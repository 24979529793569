export const es = {
  sign_in: 'Iniciar sesión',
  email: 'Correo electrónico',
  password: 'Contraseña',
  i_agree_to_toc: 'Acepto los términos y condiciones.',
  terms_and_conditions: 'Términos y condiciones',
  privacy_policy: 'Política de privacidad',
  already_have_an_account: '¿Ya tienes una cuenta?',
  sign_up: 'Registrarse',
  dont_have_an_account: '¿No tienes una cuenta?',
  forgot_password: '¿Olvidaste tu contraseña?',
  email_already_in_use: 'Correo electrónico ya está en uso',
  weak_password: 'Contraseña débil',
  too_many_attempts: 'Demasiados intentos',
  invalid_email_or_password: 'Correo electrónico o contraseña no válidos',
  user_already_registered: 'Usuario ya registrado',
  unexpected_error: 'Error inesperado',
  audios: 'Audios',
  name: 'Nombre',
  auto: 'Auto',
  auto_detect: 'Detectar automáticamente',
  speaker_num: 'Número de oradores',
  speaker_num_reason:
    'Si nos indicas el número de oradores, obtendremos un mejor resultado de transcripción.',
  audio_language: 'Idioma del audio',
  your_email: 'Tu correo electrónico',
  email_result:
    'Te enviaremos un correo electrónico con el resultado de la transcripción.',
  provide_language:
    'Si nos indicas el idioma del audio, obtendremos un mejor resultado de transcripción.',
  upload_audio: 'Subir audio',
  upload_file: 'Subir archivo',
  process_audio: 'Procesando tu archivo',
  process_audio_des:
    'Por favor espera mientras se transcribe tu audio. Puede tomar unos minutos.',
  uploading: 'Subiendo',
  pending: 'Pendiente',
  uploaded: 'Subido',
  failed: 'Fallido',
  status: 'Estado',
  language: 'Idioma',
  speakers: 'Oradores',
  duration: 'Duración',
  done: 'Hecho',
  close: 'Cerrar',
  queuing: 'En cola',
  created_at: 'Creado el',
  records: 'Registros',
  transcribe: 'Transcribir',
  delete: 'Eliminar',
  processing: 'Procesando',
  from_youtube: 'Desde Youtube',
  upload_files: 'Subir archivos',
  place_youtube_link_here: 'Ingresa el enlace de Youtube aquí',
  submit: 'Enviar',
  email_notification:
    'El tiempo de espera depende de la duración y complejidad del archivo de audio. Por lo general, puedes esperar recibir la transcripción en unas pocas horas. Te notificaremos por correo electrónico tan pronto como esté lista.',
  filters: 'Filtros',
  filter: 'Filtro',
  replace: 'Reemplazar',
  share_and_export: 'Compartir y exportar',
  replace_all: 'Reemplazar todo',
  next: 'Siguiente',
  previous: 'Anterior',
  find: 'Buscar',
  replace_with: 'Reemplazar con',
  x_of_x: '{current} de {total}',
  scroll_lock: 'Bloqueo de desplazamiento',
  tags: 'Etiquetas',
  bookmark: 'Marcador',
  audio: 'Audio',
  cancel: 'Cancelar',
  export: 'Exportar',
  export_format: 'Formato de exportación',
  copy_text: 'Copiar texto',
  monologue: 'Monólogo',
  include_timestamps: 'Incluir marcas de tiempo',
  include_speaker_names: 'Incluir nombres de los oradores',
  combine_same_speaker: 'Combinar segmentos del mismo orador',
  export_mode: 'Modo de exportación',
  no_preview: 'Sin vista previa',
  copy: 'Copiar',
  copied: 'Copiado',
  unlock_pro_feature:
    'Para desbloquear esta función, actualiza a la suscripción "Pro".',
  pro_features: 'Funciones Pro',
  pro_minutes: '1800 minutos / mes',
  pro_interact_with_audio: 'Interactuar con el audio',
  pro_interact_with_audio_des: 'Hacer preguntas a tu audio',
  pro_summary: 'Resumen de IA',
  pro_summary_des: 'Resumir tu audio',
  pro_priority: 'Cola de prioridad',
  pro_priority_des: 'Procesar el audio con prioridad',
  pro_multi_speakers: 'Reconocimiento de múltiples oradores',
  pro_multi_speakers_des:
    'Detecta y etiqueta con precisión diferentes oradores',
  redirect_to_stripe: 'Serás redirigido a Stripe para completar el pago.',
  x_per_month: '{x} / Mes',
  x_per_year: '{x} / Año',
  trash: 'Papelera',
  dashboard: 'Panel',
  logout: 'Cerrar sesión',
  are_you_sure_to_delete:
    '¿Estás seguro de eliminar los registros seleccionados?',
  restore: 'Restaurar',
  are_you_sure_to_restore:
    '¿Estás seguro de restaurar los registros seleccionados?',
  deleted_at: 'Eliminado el',
  hidden_segment: '{x} segmentos están ocultos después de {y} minutos.',
  settings: 'Configuración',
  subscription: 'Suscripción',
  default_transcription_language: 'Idioma de transcripción predeterminado',
  contact_us: 'Contáctanos',
  security: 'Seguridad',
  about_us: 'Acerca de nosotros',
  download_app: 'Descargar aplicación',
  download: 'Descargar',
  app_store: 'App Store',
  play_store: 'Play Store',
  upload_voice: 'Subir voz',
  no_audio_found: 'No se encontró audio',
  please_input_valid_email_first:
    'Por favor ingresa un correo electrónico válido primero',
  handling_audio:
    'Estamos transcribiendo tu audio. Por favor espera un momento.',
  welcome_to_leave:
    'Eres bienvenido/a a dejar esta página, ya que te enviaremos un correo electrónico con el resultado de la transcripción.',
  send_your_result:
    'Te enviaremos un correo electrónico con el resultado de la transcripción.',
  transcribe_audio_thank:
    '¡Gracias por usar nuestro servicio de transcripción! Estamos trabajando arduamente en transcribir tu audio y agradecemos tu paciencia.',
  transcribe_audio_no_stay:
    'Tu transcripción puede tardar un poco, pero no te preocupes, no necesitas quedarte en esta página. Te enviaremos por correo electrónico los resultados tan pronto como estén listos.',
  date: 'Fecha',
  sign_up_to_access_more_functions: 'Regístrate para acceder a más funciones',
  you_can_use_functions:
    'Puedes utilizar las siguientes funciones después de iniciar sesión',
  know_more: 'Saber más',
  modify_transcription: 'Modificar transcripción de texto',
  play_audio: 'Reproducir audio',
  content_filters: 'Filtros de contenido',
  export_diff_type: 'Exportar diferentes tipos de archivos',
  screenshots: 'Capturas de pantalla',
  free_trial: 'Prueba gratuita',
  pricing: 'Precios',
  faq: 'Preguntas frecuentes',
  contact: 'Contacto',
  login: 'Iniciar sesión',
  register: 'Registrarse',
  please_login_before_purchase:
    'Por favor inicia sesión antes de realizar la compra',
  no_records_found: 'No se encontraron registros',
  x_minutes: '{x} Minutos',
  confirm: 'Confirmar',
  notification_mobile_app: 'Notificación de aplicación móvil',
  notification_mobile_app_des:
    'Reciba una notificación en la aplicación móvil cuando el resultado de la transcripción esté listo',
  notification_email: 'Notificación por correo electrónico',
  notification_email_des:
    'Reciba una notificación por correo electrónico cuando el resultado de la transcripción esté listo',
  video: 'Video',
  or: 'O',
  sign_in_with_google: 'Iniciar sesión con Google',
  sign_in_with_apple: 'Iniciar sesión con Apple',
  login_method: 'Método de inicio de sesión',
  delete_account: 'Eliminar cuenta',
  delete_account_confirm_3_time:
    'Presione el botón de eliminación 3 veces para confirmar',
  popup_blocked: 'Bloqueo de ventanas emergentes',
  account_info: 'Información de la cuenta',
  redeem: 'Canjear',
  redeem_code: 'Canjear código',
  summary_des1:
    'El servicio de resumen proporciona un resumen del resultado de la transcripción. Puede resumir el resultado de la transcripción en unas pocas frases.',
  summary_des2: 'Tu antiguo resumen será reemplazado por el nuevo resumen.',
  consume_transcription_quota: 'Consumir cuota de transcripción',
  summary: 'Resumen',
  query: 'Consulta',
  mins_per_query: '{x} minutos por consulta',
  information_not_found: 'Información no encontrada',
  permission_deny: 'Permiso denegado',
  please_login_to_check_the_result:
    'Por favor inicie sesión para verificar el resultado',
  audio_length: 'Duración del audio',
  delete_permanently: 'Eliminar permanentemente',
  are_you_sure_to_delete_permanently:
    '¿Está seguro de que desea eliminar permanentemente?',
  delete_audios_move_to_bin:
    'Los registros eliminados se moverán a la papelera. Todos los registros en la papelera serán eliminados después de 30 días.',
  payment_id: 'ID de pago',
  current_plan: 'Plan actual',
  plan_name: 'Nombre del plan',
  expire_date: 'Fecha de expiración',
  subscription_method: 'Método de suscripción',
  payment_method: 'Método de pago',
  payment_history: 'Historial de pagos',
  amount: 'Cantidad',
  invoice: 'Factura',
  receipt: 'Recibo',
  link: 'Enlace',
  edit: 'Editar',
  back: 'Atrás',
  return: 'Regresar',
  cancel_subscription: 'Cancelar suscripción',
  cancel_subscription_google_play:
    'En tu dispositivo Android > Ve a Google Play Store > Cuenta > Pagos y suscripciones > Suscripciones > SoundType AI > Cancelar suscripción',
  cancel_subscription_apple_store:
    'En tu dispositivo iOS > Ve a Ajustes > [tu nombre] > Suscripciones > SoundType AI > Cancelar suscripción',
  delete_account_des:
    'Eliminar cuenta eliminará todos tus datos permanentemente.',
  delete_account_subscription:
    'Si tienes una suscripción, por favor cancela tu suscripción primero.',
  keep_subscription: 'Mantener Suscripción',
  cancel_subscription_feature1: 'Grabaciones',
  cancel_subscription_feature1_des:
    'Solo podrás ver los primeros {x} minutos de tus grabaciones.',
  cancel_subscription_feature2: 'Tiempo de transcripción',
  cancel_subscription_feature2_des:
    'Solo podrás transcribir {x} minutos por mes.',
  cancel_subscription_count:
    'Por favor presiona el botón {x} veces para confirmar',
  next_payment_date: 'Fecha del próximo pago',
  basic_plan: 'Plan Básico',
  resume_subscription: 'Reanudar suscripción',
  active: 'Activo',
  expired: 'Expirado',
  auto_renewal_clause: 'Cláusula de renovación automática',
  auto_renewal_payment_web:
    'Su suscripción se renovará automáticamente a menos que la cancele al menos 24 horas antes del final del período actual. Se le cobrará la renovación dentro de las 24 horas anteriores al final del período actual. Puede gestionar y cancelar sus suscripciones yendo a "Configuración" > "Facturación".',
  auto_renewal_terms_and_conditions:
    'Al suscribirte, aceptas nuestros Términos y Condiciones y Política de Privacidad.',
  payment: 'Pago',
  monthly: 'Mensual',
  annual: 'Anual',
  minutes_per_month: '{x} minutos/mes',
  buy_now: 'Comprar Ahora',
  yearly: 'Anual',
  yearly_save: 'Ahorra hasta {x}%',
  pro_plan: 'Pro',
  business_plan: 'Negocios',
  enterprise_plan: 'Empresarial',
  everything_in_basic: 'Todo en Básico, más',
  everything_in_pro: 'Todo en Pro, más',
  everything_in_business: 'Todo en Negocios, más',
  ai_chat_x: 'Chat IA x {x}',
  ai_summary_x: 'Resumen IA x {x}',
  max_x_minutes_per_transcription: 'Máx {x} minutos por transcripción',
  basic_des2: 'Identificación de hablantes',
  basic_des3: 'Transcripción de archivos de audio/vídeo',
  basic_des4: 'Revisar y editar transcripciones',
  pro_des2: 'Cola de Prioridad',
  pro_des3: 'Exportar en formato SRT',
  pro_des4: 'Resumen IA ilimitado',
  business_des1: 'Informe de análisis de uso',
  business_des2: 'Espacio de trabajo colaborativo con miembros del equipo',
  business_des3: 'Compartir y editar transcripciones dentro de tu equipo.',
  business_des4: 'Controlar permisos de compartición',
  coming_soon: 'Próximamente',
  get_started: 'Empezar',
  bill_annually_x: 'Facturado {x} anualmente',
  no_credit_card_required: 'No se requiere tarjeta de crédito',
  save_x: 'Ahorra {x}%',
  price_per_month: ' / mes',
  not_available: 'No disponible',
  pricing_title: 'Precios',
  upgrade: 'Actualizar',
  ask_question_to_your_audio: 'Haz una pregunta a tu audio',
  monthly_minutes: 'Minutos mensuales',
  payment_interval: 'Intervalo de pago',
  annually: 'Anualmente',
  billing: 'Facturación',
  subscription_plan: 'Plan',
  please_cancel_your_current_subscription_first:
    'Por favor, cancela tu suscripción actual primero',
  please_cancel_your_current_subscription_first_des:
    'Para cambiar tu plan de suscripción, necesitarás cancelar tu suscripción actual primero.',
  cancel_subscription_instruction:
    'Ve a Configuración > Facturación > Cancelar suscripción',
  website_version: 'Versión del sitio web',
  change_to_annual_plan: 'Cambiar al plan anual',
  update_annual_mobile_platform_reminder: 'Recordatorio',
  update_annual_mobile_platform_reminder_des:
    'Por favor, ten en cuenta que necesitas cancelar tu suscripción actual en {x} antes de cambiar al plan anual. De lo contrario, se te cobrará dos veces.',
  i_understand: 'Entiendo',
  no_credit: 'No hay suficientes minutos',
  re_submit: 'Volver a enviar',
  loading_audio: 'Cargando audio',
  your_password: 'Tu contraseña',
  home: 'Inicio',
  title_a_z: 'Título A-Z',
  title_z_a: 'Título Z-A',
  older_first: 'Más antiguo primero',
  newest_first: 'Más reciente primero',
  table_item_count: '{x} - {y} de {z} elementos',
  starred: 'Destacado',
  search: 'Buscar',
  create_folder: 'Crear carpeta',
  folder_name: 'Nombre de la carpeta',
  create: 'Crear',
  export_preview: 'Exportar vista previa',
  combine_segments: 'Combinar segmentos',
  export_audio_only:
    'Solo puede exportar audio cuando se está transcribiendo el audio.',
  transcribe_remaining_x_minutes: 'Transcribir los {x} minutos restantes',
  x_minutes_remain_be_transcribed: 'Quedan {x} minutos por transcribir',
  upgrade_plan_to_transcribe:
    'Actualice su plan para transcribir el audio completo',
  only_paid_user_can_use:
    'Solo los usuarios de pago pueden transcribir los minutos restantes',
  converting_to_audio: 'Convirtiendo a audio',
  move_to_folder: 'Mover a la carpeta',
  move_audios_to_folder: 'Mover los audios seleccionados a la carpeta',
  please_select_folder: 'Por favor, seleccione una carpeta',
  empty_folder: 'Carpeta vacía',
  move: 'Mover',
  user_not_found:
    'Usuario no encontrado. No se pudo localizar al usuario especificado. Verifique los detalles y vuelva a intentarlo, o contacte al soporte si necesita más asistencia.',
  invalid_link: 'Enlace no válido',
  translation: 'Traducción',
  translate: 'Traducir',
  translate_des1:
    'El servicio de traducción proporciona una traducción por IA del resultado de la transcripción. Puede traducir el resultado de la transcripción a otro idioma.',
  translate_des2: 'El texto traducido no es editable en este momento.',
  translate_des3:
    'Su antigua traducción será reemplazada por la nueva traducción.',
  translating: 'Traduciendo',
  translating_des:
    'El proceso de traducción está en curso. Por favor, espere un momento. Le notificaremos cuando la traducción esté lista.',
  please_select_language: 'Por favor, seleccione un idioma',
  display_language: 'Idioma de visualización',
  both: 'Ambos',
  original: 'Original',
  translated: 'Traducido',
  default_payment_currency_x_set:
    'Su moneda de pago está configurada en {x}. Si desea cambiarla, contáctenos en {email} .',
  invalid_link_des:
    'El enlace de YouTube es inválido. Los videos en vivo, listas de reproducción, contenido privado, con restricción de edad o bloqueado por región no son compatibles.',
  free_member_limited_view:
    'Los usuarios del plan básico solo tendrán acceso a los primeros {x} minutos del resultado de la transcripción.',
  free_user_service_once:
    'Los usuarios del plan básico solo pueden usar este servicio una vez.',
  free_user_summary:
    'Los usuarios del plan básico solo pueden usar este servicio una vez. Por favor, actualice a Pro para resumir más audios.',
  free_user_query:
    'Los usuarios del plan básico solo pueden usar este servicio una vez. Por favor, actualice a Pro para hacer más consultas.',
  cancel_subscription_des:
    'Su suscripción se cancelará al final del ciclo de facturación actual ({x}). Después de esa fecha, será degradado al plan básico y ya no podrá acceder a las funciones del plan Pro:',
  free_member_limited_transcribe_view:
    'Solo los primeros {x} minutos serán transcritos y visibles para los usuarios del plan básico.',
  reset_password_email_sent:
    'Por favor, revise su correo electrónico. Si esta cuenta existe, se enviará un correo electrónico para restablecer la contraseña.',
  m_downloads: '1M+ Descargas',
  provider: 'Proveedor',
  size: 'Tamaño',
  category: 'Categoría',
  productivity: 'Productividad',
  languages: 'Idiomas',
  languages_90: '90+ idiomas',
  app_des:
    '¡Transforme palabras habladas en texto sin esfuerzo con SoundType AI! Nuestra aplicación de vanguardia convierte grabaciones de voz y archivos de audio en transcripciones de texto altamente precisas. Ya sea que trabaje con reuniones, entrevistas, podcasts o contenido de video, SoundType AI ofrece precisión y eficiencia.<br><br>Las características clave incluyen:<br>- Identificación avanzada de hablantes: Distinga fácilmente a los hablantes individuales.<br>- Resúmenes impulsados por IA: Genere resúmenes concisos e informativos de sus transcripciones.<br>- Soporte global de idiomas: Transcriba conversaciones en más de 90 idiomas con facilidad.<br>- Funciones de audio mejoradas: Diseñado para profesionales, creadores y empresas por igual.<br><br>Diga adiós a las tediosas transcripciones manuales y adopte una manera más inteligente y rápida de convertir audio en texto con SoundType AI.',
  click_or_drop_file: 'Haga clic o arrastre audio / video',
  remain_x_y_minutes: 'Quedan {x}/{y} minutos',
  my_account: 'Mi cuenta',
  please_fill_your_email: 'Por favor, introduce tu correo electrónico',
  please_select_language_first: 'Por favor, selecciona el idioma primero',
  please_select_audio_first: 'Por favor, selecciona un audio primero',
  please_fill_youtube_link: 'Por favor, introduce el enlace de Youtube',
  speaker_person_x: 'Orador {x}',
  sign_up_to_unlock_des:
    'Regístrate para editar transcripciones, exportar archivos y acceder a resúmenes, traducciones y más con tecnología de IA. ¡Simplifica tu flujo de trabajo hoy mismo!',
  your_audio_being_transcribed: 'Tu audio está siendo transcrito...',
  sign_up_to_unlock: 'Regístrate para explorar más funciones',
  try_for_free_title: 'Prueba gratis',
  try_for_free_subtitle:
    'Experimenta una IA mejorada, mayor precisión y características avanzadas',
  purchase_success: '¡Pago exitoso!',
  purchase_success_des:
    'Gracias por su compra. Su transacción se completó con éxito.',
  auto_redirect_to_dashboard:
    'Será redirigido a su panel en breve. Si la redirección no ocurre dentro de 5 segundos, haga clic en el siguiente enlace para proceder manualmente:',
  need_assistance: '¿Necesita ayuda? Contáctenos en {email}',
  invalid_format: 'Formato inválido',
}
