export const fr = {
  sign_in: 'Se connecter',
  email: 'Email',
  password: 'Mot de passe',
  i_agree_to_toc: "J'accepte les conditions d'utilisation.",
  terms_and_conditions: "Conditions d'utilisation",
  privacy_policy: 'Politique de confidentialité',
  already_have_an_account: 'Vous avez déjà un compte?',
  sign_up: "S'inscrire",
  dont_have_an_account: "Vous n'avez pas de compte?",
  forgot_password: 'Mot de passe oublié?',
  email_already_in_use: 'Email déjà utilisé',
  weak_password: 'Mot de passe faible',
  too_many_attempts: 'Trop de tentatives',
  invalid_email_or_password: 'Email ou mot de passe invalide',
  user_already_registered: 'Utilisateur déjà enregistré',
  unexpected_error: 'Erreur inattendue',
  audios: 'Audios',
  name: 'Nom',
  auto: 'Automatique',
  auto_detect: 'Détection automatique',
  speaker_num: 'Nombre de locuteurs',
  speaker_num_reason:
    'Si vous nous indiquez le nombre de locuteurs, nous vous fournirons un meilleur résultat de transcription.',
  audio_language: 'Langue audio',
  your_email: 'Votre email',
  email_result:
    'Nous vous enverrons un email avec le résultat de la transcription.',
  provide_language:
    "Si vous nous indiquez la langue de l'audio, nous vous fournirons un meilleur résultat de transcription.",
  upload_audio: "Télécharger l'audio",
  upload_file: 'Télécharger un fichier',
  process_audio: 'Traitement de votre fichier',
  process_audio_des:
    'Veuillez patienter pendant que votre audio est en cours de transcription. Cela peut prendre quelques minutes.',
  uploading: 'Téléchargement en cours',
  pending: 'En attente',
  uploaded: 'Téléchargé',
  failed: 'Échec',
  status: 'Statut',
  language: 'Langue',
  speakers: 'Locuteurs',
  duration: 'Durée',
  done: 'Terminé',
  close: 'Fermer',
  queuing: "En file d'attente",
  created_at: 'Créé le',
  records: 'Enregistrements',
  transcribe: 'Transcrire',
  delete: 'Supprimer',
  processing: 'En cours de traitement',
  from_youtube: 'À partir de YouTube',
  upload_files: 'Télécharger des fichiers',
  place_youtube_link_here: 'Placez le lien YouTube ici',
  submit: 'Soumettre',
  email_notification:
    "Le délai de traitement dépend de la durée et de la complexité du fichier audio. En général, vous recevrez votre transcription dans quelques heures. Nous vous enverrons une notification par email dès qu'elle sera prête.",
  filters: 'Filtres',
  filter: 'Filtrer',
  replace: 'Remplacer',
  share_and_export: 'Partager et exporter',
  replace_all: 'Remplacer tout',
  next: 'Suivant',
  previous: 'Précédent',
  find: 'Trouver',
  replace_with: 'Remplacer par',
  x_of_x: '{current} sur {total}',
  scroll_lock: 'Verrouillage du défilement',
  tags: 'Balises',
  bookmark: 'Signet',
  audio: 'Audio',
  cancel: 'Annuler',
  export: 'Exporter',
  export_format: "Format d'exportation",
  copy_text: 'Copier le texte',
  monologue: 'Monologue',
  include_timestamps: 'Inclure les horodatages',
  include_speaker_names: 'Inclure les noms des locuteurs',
  combine_same_speaker: 'Regrouper les segments du même locuteur',
  export_mode: "Mode d'exportation",
  no_preview: 'Aucun aperçu',
  copy: 'Copier',
  copied: 'Copié',
  unlock_pro_feature:
    "Pour débloquer cette fonctionnalité, passez à l'abonnement 'Pro'.",
  pro_features: 'Fonctionnalités Pro',
  pro_minutes: '1800 minutes / mois',
  pro_interact_with_audio: "Interagir avec l'audio",
  pro_interact_with_audio_des: 'Poser des questions à votre audio',
  pro_summary: 'Résumé AI',
  pro_summary_des: 'Résumez votre audio',
  pro_priority: "File d'attente prioritaire",
  pro_priority_des: 'Traitement prioritaire des fichiers audio',
  pro_multi_speakers: 'Reconnaissance multi-locuteurs',
  pro_multi_speakers_des:
    'Détecte et étiquette précisément les différents locuteurs',
  redirect_to_stripe:
    'Vous serez redirigé vers Stripe pour finaliser le paiement.',
  x_per_month: '{x} / mois',
  x_per_year: '{x} / an',
  trash: 'Corbeille',
  dashboard: 'Tableau de bord',
  logout: 'Se déconnecter',
  are_you_sure_to_delete:
    'Êtes-vous sûr de vouloir supprimer les enregistrements sélectionnés?',
  restore: 'Restaurer',
  are_you_sure_to_restore:
    'Êtes-vous sûr de vouloir restaurer les enregistrements sélectionnés?',
  deleted_at: 'Supprimé le',
  hidden_segment: '{x} segments sont masqués après {y} minutes.',
  settings: 'Paramètres',
  subscription: 'Abonnement',
  default_transcription_language: 'Langue de transcription par défaut',
  contact_us: 'Contactez-nous',
  security: 'Sécurité',
  about_us: 'À propos de nous',
  download_app: "Télécharger l'application",
  download: 'Télécharger',
  app_store: 'App Store',
  play_store: 'Play Store',
  upload_voice: 'Télécharger la voix',
  no_audio_found: 'Aucun audio trouvé',
  please_input_valid_email_first:
    "Veuillez d'abord saisir une adresse email valide",
  handling_audio:
    'Nous transcrivons votre audio. Veuillez patienter un instant.',
  welcome_to_leave:
    'Vous êtes libre de quitter cette page, nous vous enverrons un email avec le résultat de la transcription.',
  send_your_result:
    'Nous vous enverrons un email avec le résultat de la transcription.',
  transcribe_audio_thank:
    "Merci d'utiliser notre service de transcription ! Nous travaillons dur pour transcrire votre audio et nous vous remercions de votre patience.",
  transcribe_audio_no_stay:
    "Votre transcription peut prendre un peu de temps, mais ne vous inquiétez pas, vous n'avez pas besoin de rester sur cette page. Nous vous enverrons les résultats par email dès qu'ils seront prêts.",
  date: 'Date',
  sign_up_to_access_more_functions:
    'Inscrivez-vous pour accéder à plus de fonctions',
  you_can_use_functions:
    'Vous pouvez utiliser les fonctions suivantes après vous être connecté',
  know_more: 'En savoir plus',
  modify_transcription: 'Modifier la transcription du texte',
  play_audio: "Lire l'audio",
  content_filters: 'Filtres de contenu',
  export_diff_type: 'Exporter différents types de fichiers',
  screenshots: "Captures d'écran",
  free_trial: 'Essai gratuit',
  pricing: 'Tarification',
  faq: 'FAQ',
  contact: 'Contact',
  login: 'Se connecter',
  register: "S'inscrire",
  please_login_before_purchase:
    "Veuillez vous connecter avant d'effectuer un achat",
  no_records_found: 'Aucun enregistrement trouvé',
  x_minutes: '{x} Minutes',
  confirm: 'Confirmer',
  notification_mobile_app: "Notification de l'application mobile",
  notification_mobile_app_des:
    "Recevez une notification sur l'application mobile lorsque le résultat de la transcription est prêt",
  notification_email: 'Notification par email',
  notification_email_des:
    'Recevez une notification par e-mail lorsque le résultat de la transcription est prêt',
  video: 'Vidéo',
  or: 'Ou',
  sign_in_with_google: 'Se connecter avec Google',
  sign_in_with_apple: 'Se connecter avec Apple',
  login_method: 'Méthode de connexion',
  delete_account: 'Supprimer le compte',
  delete_account_confirm_3_time:
    'Appuyez sur le bouton de suppression 3 fois pour confirmer',
  popup_blocked: 'Bloqué par le bloqueur de pop-up',
  account_info: 'Informations du compte',
  redeem: 'Utiliser',
  redeem_code: 'Utiliser le code',
  summary_des1:
    'Le service de résumé fournit un résumé du résultat de la transcription. Il peut résumer le résultat de la transcription en quelques phrases.',
  summary_des2: 'Votre ancien résumé sera remplacé par le nouveau résumé.',
  consume_transcription_quota: 'Consommer le quota de transcription',
  summary: 'Résumé',
  query: 'Requête',
  mins_per_query: '{x} minutes par requête',
  information_not_found: 'Information introuvable',
  permission_deny: 'Permission refusée',
  please_login_to_check_the_result:
    'Veuillez vous connecter pour vérifier le résultat',
  audio_length: "Durée de l'audio",
  delete_permanently: 'Supprimer définitivement',
  are_you_sure_to_delete_permanently:
    'Êtes-vous sûr de vouloir supprimer définitivement?',
  delete_audios_move_to_bin:
    'Les enregistrements supprimés seront déplacés vers la corbeille. Tous les enregistrements dans la corbeille seront supprimés après 30 jours.',
  payment_id: 'ID de paiement',
  current_plan: 'Plan actuel',
  plan_name: 'Nom du plan',
  expire_date: "Date d'expiration",
  subscription_method: "Méthode d'abonnement",
  payment_method: 'Méthode de paiement',
  payment_history: 'Historique de paiement',
  amount: 'Montant',
  invoice: 'Facture',
  receipt: 'Reçu',
  link: 'Lien',
  edit: 'Modifier',
  back: 'Retour',
  return: 'Retourner',
  cancel_subscription: "Annuler l'abonnement",
  cancel_subscription_google_play:
    "Sur votre appareil Android > Accédez au Google Play Store > Compte > Paiements et abonnements > Abonnements > SoundType AI > Annuler l'abonnement",
  cancel_subscription_apple_store:
    "Sur votre appareil iOS > Accédez aux Réglages > [votre nom] > Abonnements > SoundType AI > Annuler l'abonnement",
  delete_account_des:
    'Supprimer le compte supprimera toutes vos données de manière permanente.',
  delete_account_subscription:
    "Si vous avez un abonnement, veuillez annuler votre abonnement d'abord.",
  keep_subscription: "Garder l'abonnement",
  cancel_subscription_feature1: 'Enregistrements',
  cancel_subscription_feature1_des:
    'Vous ne pourrez visualiser que les premières {x} minutes de vos enregistrements.',
  cancel_subscription_feature2: 'Temps de transcription',
  cancel_subscription_feature2_des:
    'Vous ne pourrez transcrire que {x} minutes par mois.',
  cancel_subscription_count:
    'Veuillez appuyer sur le bouton {x} fois pour confirmer',
  next_payment_date: 'Date du prochain paiement',
  basic_plan: 'Plan Basique',
  resume_subscription: "Reprendre l'abonnement",
  active: 'Actif',
  expired: 'Expiré',
  auto_renewal_clause: 'Clause de renouvellement automatique',
  auto_renewal_payment_web:
    "Votre abonnement sera automatiquement renouvelé à moins que vous ne l'annuliez au moins 24 heures avant la fin de la période en cours. Votre compte sera facturé pour le renouvellement dans les 24 heures précédant la fin de la période en cours. Vous pouvez gérer et annuler vos abonnements en allant dans « Paramètres » > « Facturation ».",
  auto_renewal_terms_and_conditions:
    'En vous abonnant, vous acceptez nos Conditions générales et notre Politique de confidentialité.',
  payment: 'Paiement',
  monthly: 'Mensuel',
  annual: 'Annuel',
  minutes_per_month: '{x} minutes/mois',
  buy_now: 'Acheter maintenant',
  yearly: 'Annuel',
  yearly_save: 'Économisez jusqu’à {x}%',
  pro_plan: 'Pro',
  business_plan: 'Entreprise',
  enterprise_plan: 'Grande Entreprise',
  everything_in_basic: 'Tout dans Basic, plus',
  everything_in_pro: 'Tout dans Pro, plus',
  everything_in_business: 'Tout dans Entreprise, plus',
  ai_chat_x: 'Chat IA x {x}',
  ai_summary_x: 'Résumé IA x {x}',
  max_x_minutes_per_transcription: 'Max {x} minutes par transcription',
  basic_des2: 'Identification de l’orateur',
  basic_des3: 'Transcription de fichiers audio/vidéo',
  basic_des4: 'Revoir et modifier les transcriptions',
  pro_des2: 'File d’attente prioritaire',
  pro_des3: 'Exportation au format SRT',
  pro_des4: 'Résumé IA illimité',
  business_des1: 'Rapport d’analyse d’utilisation',
  business_des2: 'Espace de travail collaboratif avec les membres de l’équipe',
  business_des3:
    'Partager et modifier les transcriptions au sein de votre équipe.',
  business_des4: 'Contrôler les permissions de partage',
  coming_soon: 'Prochainement',
  get_started: 'Commencer',
  bill_annually_x: 'Facturé {x} annuellement',
  no_credit_card_required: 'Aucune carte de crédit requise',
  save_x: 'Économisez {x}%',
  price_per_month: ' / mois',
  not_available: 'Indisponible',
  pricing_title: 'Tarification',
  upgrade: 'Mettre à niveau',
  ask_question_to_your_audio: 'Poser une question à votre audio',
  monthly_minutes: 'Minutes mensuelles',
  payment_interval: 'Intervalle de paiement',
  annually: 'Annuellement',
  billing: 'Facturation',
  subscription_plan: 'Abonnement',
  please_cancel_your_current_subscription_first:
    'Veuillez d’abord annuler votre abonnement actuel',
  please_cancel_your_current_subscription_first_des:
    "Pour changer votre plan d'abonnement, vous devrez annuler votre abonnement actuel en premier.",
  cancel_subscription_instruction:
    'Aller à Paramètres > Facturation > Annuler l’abonnement',
  website_version: 'Version du site web',
  change_to_annual_plan: 'Changer pour le plan annuel',
  update_annual_mobile_platform_reminder: 'Rappel',
  update_annual_mobile_platform_reminder_des:
    'Veuillez noter que vous devez annuler votre abonnement actuel sur {x} avant de passer au plan annuel. Sinon, vous serez facturé deux fois.',
  i_understand: 'Je comprends',
  no_credit: 'Pas assez de minutes',
  re_submit: 'Soumettre à nouveau',
  loading_audio: "Chargement de l'audio",
  your_password: 'Votre mot de passe',
  home: 'Accueil',
  title_a_z: 'Titre A-Z',
  title_z_a: 'Titre Z-A',
  older_first: "Le plus ancien d'abord",
  newest_first: "Le plus récent d'abord",
  table_item_count: '{x} - {y} sur {z} éléments',
  starred: 'Étoilé',
  search: 'Recherche',
  create_folder: 'Créer un dossier',
  folder_name: 'Nom du dossier',
  create: 'Créer',
  export_preview: "Aperçu de l'exportation",
  combine_segments: 'Combiner les segments',
  export_audio_only:
    "Vous pouvez exporter uniquement l'audio lorsque l'audio est en cours de transcription.",
  transcribe_remaining_x_minutes: 'Transcrire les {x} minutes restantes',
  x_minutes_remain_be_transcribed: 'Il reste {x} minutes à transcrire',
  upgrade_plan_to_transcribe:
    "Mettez à jour votre plan pour transcrire l'audio complet",
  only_paid_user_can_use:
    'Seuls les utilisateurs payants peuvent transcrire les minutes restantes',
  converting_to_audio: 'Conversion en audio',
  move_to_folder: 'Déplacer vers le dossier',
  move_audios_to_folder: 'Déplacer les audios sélectionnés vers le dossier',
  please_select_folder: 'Veuillez sélectionner un dossier',
  empty_folder: 'Dossier vide',
  move: 'Déplacer',
  user_not_found:
    "Utilisateur non trouvé. L'utilisateur spécifié n'a pas pu être localisé. Veuillez vérifier les détails et réessayer, ou contactez le support pour obtenir de l'aide.",
  invalid_link: 'Lien invalide',
  translation: 'Traduction',
  translate: 'Traduire',
  translate_des1:
    'Le service de traduction fournit une traduction par IA du résultat de transcription. Il peut traduire le résultat de la transcription dans une autre langue.',
  translate_des2: "Le texte traduit n'est pas modifiable pour le moment.",
  translate_des3:
    'Votre ancienne traduction sera remplacée par la nouvelle traduction.',
  translating: 'Traduction en cours',
  translating_des:
    'Le processus de traduction est en cours. Veuillez patienter un moment. Nous vous informerons lorsque la traduction sera prête.',
  please_select_language: 'Veuillez sélectionner une langue',
  display_language: "Langue d'affichage",
  both: 'Les deux',
  original: 'Original',
  translated: 'Traduit',
  default_payment_currency_x_set:
    'Votre devise de paiement est définie sur {x}. Si vous souhaitez la modifier, veuillez nous contacter à {email} .',
  invalid_link_des:
    'Le lien YouTube est invalide. Les vidéos en direct, les playlists, le contenu privé, limité par âge ou bloqué par région ne sont pas pris en charge.',
  free_member_limited_view:
    'Les utilisateurs du plan de base n’auront accès qu’aux {x} premières minutes du résultat de la transcription.',
  free_user_service_once:
    'Les utilisateurs du plan de base peuvent utiliser ce service une seule fois.',
  free_user_summary:
    'Les utilisateurs du plan de base peuvent utiliser ce service une seule fois. Veuillez passer au plan Pro pour résumer plus d’audios.',
  free_user_query:
    'Les utilisateurs du plan de base peuvent utiliser ce service une seule fois. Veuillez passer au plan Pro pour poser plus de questions.',
  cancel_subscription_des:
    'Votre abonnement sera annulé à la fin du cycle de facturation actuel ({x}). Après cette date, vous serez rétrogradé au plan de base et n’aurez plus accès aux fonctionnalités du plan Pro :',
  free_member_limited_transcribe_view:
    'Seules les {x} premières minutes seront transcrites et visibles pour les utilisateurs du plan de base.',
  reset_password_email_sent:
    'Veuillez vérifier votre email. Si ce compte existe, un email de réinitialisation de mot de passe sera envoyé.',
  m_downloads: '1M+ Téléchargements',
  provider: 'Fournisseur',
  size: 'Taille',
  category: 'Catégorie',
  productivity: 'Productivité',
  languages: 'Langues',
  languages_90: '90+ langues',
  app_des:
    'Transformez les mots parlés en texte sans effort avec SoundType AI ! Notre application de pointe convertit les enregistrements vocaux et les fichiers audio en transcriptions textuelles très précises. Que vous travailliez avec des réunions, des interviews, des podcasts ou du contenu vidéo, SoundType AI offre précision et efficacité.<br><br>Caractéristiques principales :<br>- Identification avancée des intervenants : Distinguez facilement les différents interlocuteurs.<br>- Résumés générés par IA : Créez des résumés concis et pertinents de vos transcriptions.<br>- Support linguistique mondial : Transcrivez des conversations dans plus de 90 langues facilement.<br>- Fonctions audio améliorées : Conçues pour les professionnels, créateurs et entreprises.<br><br>Dites adieu aux transcriptions manuelles fastidieuses et adoptez une méthode plus intelligente et rapide pour transformer l’audio en texte avec SoundType AI.',
  click_or_drop_file: 'Cliquez ou déposez un fichier audio / vidéo',
  remain_x_y_minutes: 'Restant {x}/{y} minutes',
  my_account: 'Mon compte',
  please_fill_your_email: 'Veuillez entrer votre e-mail',
  please_select_language_first: "Veuillez d'abord sélectionner une langue",
  please_select_audio_first: "Veuillez d'abord sélectionner un audio",
  please_fill_youtube_link: 'Veuillez entrer le lien Youtube',
  speaker_person_x: 'Intervenant {x}',
  sign_up_to_unlock_des:
    "Inscrivez-vous pour modifier des transcriptions, exporter des fichiers et accéder à des résumés, traductions et bien plus encore grâce à l'IA. Simplifiez votre flux de travail dès aujourd'hui !",
  your_audio_being_transcribed: 'Votre audio est en cours de transcription...',
  sign_up_to_unlock: 'Inscrivez-vous pour explorer plus de fonctionnalités',
  try_for_free_title: 'Essayez gratuitement',
  try_for_free_subtitle:
    'Découvrez une puissance IA améliorée, une précision supérieure et des fonctionnalités avancées',
  purchase_success: 'Paiement réussi !',
  purchase_success_des:
    'Merci pour votre achat. Votre transaction a été effectuée avec succès.',
  auto_redirect_to_dashboard:
    'Vous serez redirigé vers votre tableau de bord sous peu. Si la redirection ne se produit pas dans les 5 secondes, cliquez sur le lien suivant pour continuer manuellement :',
  need_assistance: "Besoin d'aide ? Contactez-nous à {email}",
  invalid_format: 'Format invalide',
}
