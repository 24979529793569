export const ja = {
  sign_in: 'ログイン',
  email: 'メールアドレス',
  password: 'パスワード',
  i_agree_to_toc: '利用規約に同意する',
  terms_and_conditions: '利用規約',
  privacy_policy: 'プライバシーポリシー',
  already_have_an_account: 'すでにアカウントをお持ちですか？',
  sign_up: 'サインアップ',
  dont_have_an_account: 'アカウントをお持ちでないですか？',
  forgot_password: 'パスワードをお忘れですか？',
  email_already_in_use: '既に使用されているメールアドレスです',
  weak_password: '弱いパスワードです',
  too_many_attempts: '試行回数が多すぎます',
  invalid_email_or_password: '無効なメールアドレスまたはパスワードです',
  user_already_registered: 'ユーザーは既に登録されています',
  unexpected_error: '予期しないエラーが発生しました',
  audios: 'オーディオ',
  name: '名前',
  auto: '自動',
  auto_detect: '自動検出',
  speaker_num: '話者の数',
  speaker_num_reason:
    '話者の数を教えていただくと、より良い転写結果を提供できます。',
  audio_language: 'オーディオ言語',
  your_email: 'あなたのメールアドレス',
  email_result: '転写結果をメールで送信します。',
  provide_language:
    'オーディオの言語を教えていただくと、より良い転写結果を提供できます。',
  upload_audio: 'オーディオをアップロード',
  upload_file: 'ファイルをアップロード',
  process_audio: 'ファイルを処理中',
  process_audio_des:
    'オーディオの転写が進行中です。数分かかる場合がありますので、お待ちください。',
  uploading: 'アップロード中',
  pending: '保留中',
  uploaded: 'アップロード済み',
  failed: '失敗しました',
  status: 'ステータス',
  language: '言語',
  speakers: '話者',
  duration: '再生時間',
  done: '完了',
  close: '閉じる',
  queuing: '待機中',
  created_at: '作成日時',
  records: '記録',
  transcribe: '転写',
  delete: '削除',
  processing: '処理中',
  from_youtube: 'YouTubeから',
  upload_files: 'ファイルをアップロード',
  place_youtube_link_here: 'YouTubeのリンクをここに入力してください',
  submit: '送信',
  email_notification:
    '転写の所要時間は、オーディオファイルの長さと複雑さによって異なります。通常、数時間以内に転写結果を受け取ることができます。準備ができ次第、メールでお知らせいたします。',
  filters: 'フィルター',
  filter: 'フィルター',
  replace: '置換',
  share_and_export: '共有とエクスポート',
  replace_all: 'すべて置換',
  next: '次へ',
  previous: '前へ',
  find: '検索',
  replace_with: '置換する文字列',
  x_of_x: '{current} / {total}',
  scroll_lock: 'スクロールロック',
  tags: 'タグ',
  bookmark: 'ブックマーク',
  audio: 'オーディオ',
  cancel: 'キャンセル',
  export: 'エクスポート',
  export_format: 'エクスポート形式',
  copy_text: 'テキストをコピー',
  monologue: 'モノローグ',
  include_timestamps: 'タイムスタンプを含む',
  include_speaker_names: '話者の名前を含む',
  combine_same_speaker: '同一話者のセグメントを結合する',
  export_mode: 'エクスポートモード',
  no_preview: 'プレビューなし',
  copy: 'コピー',
  copied: 'コピー済み',
  unlock_pro_feature:
    "この機能を利用するには、'Pro' サブスクリプションプランにアップグレードしてください。",
  pro_features: 'プロの機能',
  pro_minutes: '1800 分 / 月',
  pro_interact_with_audio: 'オーディオと対話する',
  pro_interact_with_audio_des: 'オーディオに質問する',
  pro_summary: 'AIによる要約',
  pro_summary_des: 'オーディオを要約する',
  pro_priority: '優先キュー',
  pro_priority_des: '優先的に処理されるオーディオ',
  pro_multi_speakers: 'マルチスピーカー認識',
  pro_multi_speakers_des: '異なる話者を正確に検出してラベル付けする',
  redirect_to_stripe: 'お支払いの完了のためにStripeにリダイレクトされます。',
  x_per_month: '{x} / 月',
  x_per_year: '{x} / 年',
  trash: 'ゴミ箱',
  dashboard: 'ダッシュボード',
  logout: 'ログアウト',
  are_you_sure_to_delete: '選択した記録を削除してもよろしいですか？',
  restore: '復元',
  are_you_sure_to_restore: '選択した記録を復元してもよろしいですか？',
  deleted_at: '削除日時',
  hidden_segment: '{y}分後に{x}セグメントが非表示になります。',
  settings: '設定',
  subscription: 'サブスクリプション',
  default_transcription_language: 'デフォルトの転写言語',
  contact_us: 'お問い合わせ',
  security: 'セキュリティ',
  about_us: '当社について',
  download_app: 'アプリをダウンロード',
  download: 'ダウンロード',
  app_store: 'App Store',
  play_store: 'Playストア',
  upload_voice: '音声をアップロード',
  no_audio_found: 'オーディオが見つかりません',
  please_input_valid_email_first: '有効なメールアドレスを入力してください',
  handling_audio: 'オーディオを処理中です。しばらくお待ちください。',
  welcome_to_leave:
    'このページを離れていただいても結構です。転写結果をメールでお送りします。',
  send_your_result: '転写結果をメールで送信します。',
  transcribe_audio_thank:
    '当社の転写サービスをご利用いただきありがとうございます！オーディオの転写に取り組んでいますので、お待ちいただければ幸いです。',
  transcribe_audio_no_stay:
    '転写には少し時間がかかるかもしれませんが、心配しないでください。準備ができ次第、結果をメールでお知らせします。',
  date: '日付',
  sign_up_to_access_more_functions:
    'より多くの機能にアクセスするには、サインアップしてください',
  you_can_use_functions: 'ログイン後に以下の機能を利用できます',
  know_more: '詳細を知る',
  modify_transcription: 'テキスト転写の編集',
  play_audio: 'オーディオの再生',
  content_filters: 'コンテンツフィルター',
  export_diff_type: '異なるタイプのファイルをエクスポート',
  screenshots: 'スクリーンショット',
  free_trial: '無料トライアル',
  pricing: '価格設定',
  faq: 'よくある質問',
  contact: 'お問い合わせ',
  login: 'ログイン',
  register: '登録',
  please_login_before_purchase: '購入前にログインしてください',
  no_records_found: 'レコードが見つかりませんでした',
  x_minutes: '{x} 分',
  confirm: '確認',
  notification_mobile_app: 'モバイルアプリの通知',
  notification_mobile_app_des:
    'トランスクリプションの結果が準備できたときにモバイルアプリで通知を受け取る',
  notification_email: 'メール通知',
  notification_email_des:
    'トランスクリプションの結果が準備できたときにメールで通知を受け取る',
  video: 'ビデオ',
  or: 'または',
  sign_in_with_google: 'Googleでサインイン',
  sign_in_with_apple: 'Appleでサインイン',
  login_method: 'ログイン方法',
  delete_account: 'アカウント削除',
  delete_account_confirm_3_time: '確認するには削除ボタンを3回押してください',
  popup_blocked: 'ポップアップがブロックされました',
  account_info: 'アカウント情報',
  redeem: '引き換え',
  redeem_code: 'コードを引き換える',
  summary_des1:
    'サマリーサービスは、転写結果の要約を提供します。転写結果を数文に要約することができます。',
  summary_des2: '古い要約は新しい要約で置き換えられます。',
  consume_transcription_quota: '転写クオータを消費する',
  summary: '要約',
  query: 'クエリ',
  mins_per_query: 'クエリごとに{x}分',
  information_not_found: '情報が見つかりません',
  permission_deny: '許可が拒否されました',
  please_login_to_check_the_result: '結果を確認するにはログインしてください',
  audio_length: 'オーディオの長さ',
  delete_permanently: '完全に削除',
  are_you_sure_to_delete_permanently: '本当に永久に削除しますか？',
  delete_audios_move_to_bin:
    '削除されたレコードはゴミ箱に移動します。ゴミ箱のすべてのレコードは30日後に削除されます。',
  ayment_id: '支払いID',
  current_plan: '現在のプラン',
  plan_name: 'プラン名',
  expire_date: '有効期限',
  subscription_method: '購読方法',
  payment_method: '支払い方法',
  payment_history: '支払い履歴',
  amount: '金額',
  invoice: '請求書',
  receipt: '領収書',
  link: 'リンク',
  edit: '編集',
  back: '戻る',
  return: '戻る',
  cancel_subscription: 'サブスクリプションをキャンセル',
  cancel_subscription_google_play:
    'Androidデバイスで > Google Playストアにアクセス > アカウント > 支払いとサブスクリプション > サブスクリプション > SoundType AI > サブスクリプションをキャンセル',
  cancel_subscription_apple_store:
    'iOSデバイスで > 設定 > [あなたの名前] > サブスクリプション > SoundType AI > サブスクリプションをキャンセル',
  delete_account_des:
    'アカウントを削除すると、すべてのデータが永久に削除されます。',
  delete_account_subscription:
    'サブスクリプションがある場合は、先にサブスクリプションをキャンセルしてください。',
  keep_subscription: 'サブスクリプションを維持',
  cancel_subscription_feature1: '録音',
  cancel_subscription_feature1_des: '録音の最初の{x}分のみを表示できます。',
  cancel_subscription_feature2: '書き起こし時間',
  cancel_subscription_feature2_des: '月に{x}分のみ書き起こしできます。',
  cancel_subscription_count: '確認のため、ボタンを{x}回押してください',
  next_payment_date: '次回の支払い日',
  basic_plan: 'ベーシックプラン',
  resume_subscription: 'サブスクリプションを再開',
  active: 'アクティブ',
  expired: '期限切れ',
  auto_renewal_clause: '自動更新条項',
  auto_renewal_payment_web:
    '現在の期間が終了する24時間前までに解約しない限り、サブスクリプションは自動的に更新されます。更新料は、現在の期間が終了する24時間以内にアカウントに請求されます。「設定」>「請求」からサブスクリプションを管理および解約できます。',
  auto_renewal_terms_and_conditions:
    'ご登録にあたり、弊社の利用規約およびプライバシーポリシーに同意したものとみなされます。',
  payment: '支払い',
  monthly: '月額',
  annual: '年間',
  minutes_per_month: '{x}分/月',
  buy_now: '今すぐ購入',
  yearly: '年間',
  yearly_save: '{x}%まで節約',
  pro_plan: 'プロ',
  business_plan: 'ビジネス',
  enterprise_plan: 'エンタープライズ',
  everything_in_basic: 'ベーシックの全てに加えて、',
  everything_in_pro: 'プロの全てに加えて、',
  everything_in_business: 'ビジネスの全てに加えて、',
  ai_chat_x: 'AIチャット x {x}',
  ai_summary_x: 'AIサマリー x {x}',
  max_x_minutes_per_transcription: 'トランスクリプション毎の最大{x}分',
  basic_des2: '話者識別',
  basic_des3: 'オーディオ/ビデオファイルのトランスクリプション',
  basic_des4: 'トランスクリプトの確認・編集',
  pro_des2: '優先キュー',
  pro_des3: 'SRT形式でエクスポート',
  pro_des4: '無制限AIサマリー',
  business_des1: '使用状況分析レポート',
  business_des2: 'チームメンバーとの共同ワークスペース',
  business_des3: 'チーム内でトランスクリプトを共有・編集。',
  business_des4: '共有権限の管理',
  coming_soon: '近日公開',
  get_started: '始める',
  bill_annually_x: '{x}年間で請求',
  no_credit_card_required: 'クレジットカード不要',
  save_x: '{x}%節約',
  price_per_month: ' / 月',
  not_available: '利用不可',
  pricing_title: '価格設定',
  upgrade: 'アップグレード',
  ask_question_to_your_audio: 'あなたのオーディオに質問をする',
  monthly_minutes: '月間分数',
  payment_interval: '支払い間隔',
  annually: '年間',
  billing: '請求',
  subscription_plan: 'プラン',
  please_cancel_your_current_subscription_first:
    '現在のサブスクリプションを先にキャンセルしてください',
  please_cancel_your_current_subscription_first_des:
    'サブスクリプションプランを変更するには、現在のサブスクリプションを先にキャンセルする必要があります。',
  cancel_subscription_instruction:
    '設定 > 請求 > サブスクリプションをキャンセルに移動します',
  website_version: 'ウェブサイトバージョン',
  change_to_annual_plan: '年間プランに変更',
  update_annual_mobile_platform_reminder: 'リマインダー',
  update_annual_mobile_platform_reminder_des:
    '年間プランに変更する前に{x}で現在のサブスクリプションをキャンセルする必要があることを通知してください。さもないと、二重に請求されます。',
  i_understand: '了解しました',
  no_credit: '十分な分数がありません',
  re_submit: '再送信',
  loading_audio: 'オーディオを読み込んでいます',
  your_password: 'あなたのパスワード',
  home: 'ホーム',
  title_a_z: 'タイトル A-Z',
  title_z_a: 'タイトル Z-A',
  older_first: '古い順',
  newest_first: '新しい順',
  table_item_count: '{x} - {y} の {z} 項目',
  starred: 'スター付き',
  search: '検索',
  create_folder: 'フォルダーを作成',
  folder_name: 'フォルダー名',
  create: '作成',
  export_preview: 'プレビューをエクスポート',
  combine_segments: 'セグメントを結合',
  export_audio_only:
    'オーディオがトランスクリプトされている場合にのみ、オーディオをエクスポートできます。',
  transcribe_remaining_x_minutes: '残りの {x} 分をトランスクリプト',
  x_minutes_remain_be_transcribed:
    'トランスクリプトされる残りの時間は {x} 分です',
  upgrade_plan_to_transcribe:
    '完全なオーディオをトランスクリプトするには、プランをアップグレードしてください',
  only_paid_user_can_use:
    '残りの分数をトランスクリプトできるのは、有料ユーザーのみです',
  converting_to_audio: 'オーディオに変換中',
  move_to_folder: 'フォルダーに移動',
  move_audios_to_folder: '選択したオーディオをフォルダーに移動',
  please_select_folder: 'フォルダーを選択してください',
  empty_folder: '空のフォルダー',
  move: '移動',
  user_not_found:
    'ユーザーが見つかりません。指定されたユーザーを見つけることができませんでした。詳細を確認して再試行するか、さらにサポートが必要な場合はサポートに連絡してください。',
  invalid_link: '無効なリンク',
  translation: '翻訳',
  translate: '翻訳する',
  translate_des1:
    '翻訳サービスは、転写結果のAI翻訳を提供します。転写結果を別の言語に翻訳できます。',
  translate_des2: '翻訳されたテキストは現時点では編集できません。',
  translate_des3: '以前の翻訳は新しい翻訳に置き換えられます。',
  translating: '翻訳中',
  translating_des:
    '翻訳プロセスが進行中です。しばらくお待ちください。翻訳が準備できたらお知らせします。',
  please_select_language: '言語を選択してください',
  display_language: '表示言語',
  both: '両方',
  original: 'オリジナル',
  translated: '翻訳',
  default_payment_currency_x_set:
    'お支払い通貨は {x} に設定されています。変更をご希望の場合は、{email} にお問い合わせください。',
  invalid_link_des:
    'YouTubeリンクが無効です。ライブ動画、再生リスト、非公開、年齢制限、または地域制限のコンテンツはサポートされていません。',
  free_member_limited_view:
    'ベーシックプランのユーザーは、トランスクリプション結果の最初の {x} 分のみアクセス可能です。',
  free_user_service_once:
    'ベーシックプランのユーザーは、このサービスを1回のみ利用できます。',
  free_user_summary:
    'ベーシックプランのユーザーは、このサービスを1回のみ利用できます。より多くの音声を要約するには、プロプランにアップグレードしてください。',
  free_user_query:
    'ベーシックプランのユーザーは、このサービスを1回のみ利用できます。さらに多くのクエリを行うには、プロプランにアップグレードしてください。',
  cancel_subscription_des:
    '現在の請求サイクル ({x}) の終了時にサブスクリプションがキャンセルされます。その日以降、ベーシックプランにダウングレードされ、プロプランの機能にアクセスできなくなります。',
  free_member_limited_transcribe_view:
    '最初の {x} 分のみがトランスクリプトされ、ベーシックプランのユーザーが閲覧可能です。',
  reset_password_email_sent:
    'メールをご確認ください。このアカウントが存在する場合、パスワードリセットのメールが送信されます。',
  m_downloads: '100万+ ダウンロード',
  provider: 'プロバイダー',
  size: 'サイズ',
  category: 'カテゴリー',
  productivity: '生産性',
  languages: '言語',
  languages_90: '90以上の言語',
  app_des:
    'SoundType AIで話し言葉を簡単にテキストに変換！最先端のアプリが、音声録音やオーディオファイルを高精度なテキストベースのトランスクリプトに変換します。会議、インタビュー、ポッドキャスト、またはビデオコンテンツで作業する場合でも、SoundType AIは精度と効率を提供します。<br><br>主な機能：<br>- 高度な話者識別：個々の話者を簡単に区別。<br>- AI駆動の要約：トランスクリプトの簡潔で洞察に満ちた要約を生成。<br>- グローバルな言語サポート：90以上の言語で会話を簡単にトランスクリプト。<br>- 強化されたオーディオ機能：プロフェッショナル、クリエイター、およびビジネス向けに設計。<br><br>面倒な手動トランスクリプトに別れを告げ、SoundType AIでオーディオをテキストに変換するよりスマートで迅速な方法を採用してください。',
  click_or_drop_file: 'クリックまたはオーディオ / ビデオをドロップ',
  remain_x_y_minutes: '残り {x}/{y} 分',
  my_account: 'マイアカウント',
  please_fill_your_email: 'メールアドレスを入力してください',
  please_select_language_first: 'まず言語を選択してください',
  please_select_audio_first: 'まず音声を選択してください',
  please_fill_youtube_link: 'Youtubeリンクを入力してください',
  speaker_person_x: '話者 {x}',
  sign_up_to_unlock_des:
    '登録して、転写を編集したり、ファイルをエクスポートしたり、AIを活用した要約や翻訳などにアクセスしてください。ワークフローを簡素化しましょう！',
  your_audio_being_transcribed: '音声が書き起こされています...',
  sign_up_to_unlock: 'より多くの機能を探るためにサインアップ',
  try_for_free_title: '無料で試す',
  try_for_free_subtitle:
    '強化されたAIパワー、優れた精度、拡張機能を体験してください',
  purchase_success: 'お支払い完了！',
  purchase_success_des:
    'ご購入いただきありがとうございます。お取引が正常に完了しました。',
  auto_redirect_to_dashboard:
    'まもなくダッシュボードにリダイレクトされます。5秒以内にリダイレクトされない場合は、次のリンクをクリックして手動で続行してください:',
  need_assistance: 'サポートが必要ですか？ {email} にお問い合わせください',
  invalid_format: '無効な形式',
}
