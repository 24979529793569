export const nl = {
  sign_in: 'Aanmelden',
  email: 'E-mail',
  password: 'Wachtwoord',
  i_agree_to_toc: 'Ik ga akkoord met de algemene voorwaarden.',
  terms_and_conditions: 'Algemene voorwaarden',
  privacy_policy: 'Privacybeleid',
  already_have_an_account: 'Heeft u al een account?',
  sign_up: 'Registreren',
  dont_have_an_account: 'Heeft u nog geen account?',
  forgot_password: 'Wachtwoord vergeten?',
  email_already_in_use: 'E-mailadres al in gebruik',
  weak_password: 'Zwak wachtwoord',
  too_many_attempts: 'Te veel pogingen',
  invalid_email_or_password: 'Ongeldig e-mailadres of wachtwoord',
  user_already_registered: 'Gebruiker al geregistreerd',
  unexpected_error: 'Onverwachte fout',
  audios: "Audio's",
  name: 'Naam',
  auto: 'Automatisch',
  auto_detect: 'Automatisch detecteren',
  speaker_num: 'Aantal sprekers',
  speaker_num_reason:
    'Als u ons het aantal sprekers vertelt, krijgt u een beter transcriptieresultaat.',
  audio_language: 'Audio taal',
  your_email: 'Uw e-mail',
  email_result: 'We sturen u een e-mail met het transcriptieresultaat.',
  provide_language:
    'Als u ons de audio taal vertelt, krijgt u een beter transcriptieresultaat.',
  upload_audio: 'Audio uploaden',
  upload_file: 'Bestand uploaden',
  process_audio: 'Uw bestand verwerken',
  process_audio_des:
    'Wacht even terwijl uw audio wordt getranscribeerd. Het kan enkele minuten duren.',
  uploading: 'Uploaden',
  pending: 'In behandeling',
  uploaded: 'Geüpload',
  failed: 'Mislukt',
  status: 'Status',
  language: 'Taal',
  speakers: 'Sprekers',
  duration: 'Duur',
  done: 'Klaar',
  close: 'Sluiten',
  queuing: 'In de wachtrij',
  created_at: 'Gemaakt op',
  records: 'Records',
  transcribe: 'Transcriberen',
  delete: 'Verwijderen',
  processing: 'Verwerken',
  from_youtube: 'Van YouTube',
  upload_files: 'Bestanden uploaden',
  place_youtube_link_here: 'Plaats hier de YouTube-link',
  submit: 'Verzenden',
  email_notification:
    'De verwerkingstijd hangt af van de lengte en complexiteit van het audiobestand. Over het algemeen kunt u verwachten dat u uw transcriptie binnen enkele uren ontvangt. We zullen u op de hoogte stellen via e-mail zodra deze klaar is.',
  filters: 'Filters',
  filter: 'Filter',
  replace: 'Vervangen',
  share_and_export: 'Delen en exporteren',
  replace_all: 'Alles vervangen',
  next: 'Volgende',
  previous: 'Vorige',
  find: 'Zoeken',
  replace_with: 'Vervangen door',
  x_of_x: '{current} van {total}',
  scroll_lock: 'Scroll Lock',
  tags: 'Tags',
  bookmark: 'Bladwijzer',
  audio: 'Audio',
  cancel: 'Annuleren',
  export: 'Exporteren',
  export_format: 'Exportformaat',
  copy_text: 'Tekst kopiëren',
  monologue: 'Monoloog',
  include_timestamps: 'Tijdstempels opnemen',
  include_speaker_names: 'Sprekernamen opnemen',
  combine_same_speaker: 'Gelijke sprekersegmenten combineren',
  export_mode: 'Exportmodus',
  no_preview: 'Geen voorbeeld',
  copy: 'Kopiëren',
  copied: 'Gekopieerd',
  unlock_pro_feature:
    'Om deze functie te ontgrendelen, upgrade naar het "Pro" abonnement.',
  pro_features: 'Pro-functies',
  pro_minutes: '1800 minuten / maand',
  pro_interact_with_audio: 'Interactie met audio',
  pro_interact_with_audio_des: 'Stel vragen aan uw audio',
  pro_summary: 'AI-samenvatting',
  pro_summary_des: 'Uw audio samenvatten',
  pro_priority: 'Voorrang in de wachtrij',
  pro_priority_des: 'Verwerkte audio met voorrang',
  pro_multi_speakers: 'Erkenning van meerdere sprekers',
  pro_multi_speakers_des:
    'Nauwkeurige detectie en labeling van verschillende sprekers',
  redirect_to_stripe:
    'U wordt doorgestuurd naar Stripe om de betaling te voltooien.',
  x_per_month: '{x} / Maand',
  x_per_year: '{x} / Jaar',
  trash: 'Prullenbak',
  dashboard: 'Dashboard',
  logout: 'Uitloggen',
  are_you_sure_to_delete:
    'Weet u zeker dat u de geselecteerde records wilt verwijderen?',
  restore: 'Herstellen',
  are_you_sure_to_restore:
    'Weet u zeker dat u de geselecteerde records wilt herstellen?',
  deleted_at: 'Verwijderd op',
  hidden_segment: '{x} segmenten zijn verborgen na {y} minuten.',
  settings: 'Instellingen',
  subscription: 'Abonnement',
  default_transcription_language: 'Standaard transcriptietaal',
  contact_us: 'Neem contact met ons op',
  security: 'Beveiliging',
  about_us: 'Over ons',
  download_app: 'App downloaden',
  download: 'Downloaden',
  app_store: 'App Store',
  play_store: 'Play Store',
  upload_voice: 'Stem uploaden',
  no_audio_found: 'Geen audio gevonden',
  please_input_valid_email_first: 'Voer eerst een geldig e-mailadres in',
  handling_audio:
    'We zijn bezig met het transcriberen van uw audio. Wacht even.',
  welcome_to_leave:
    'U kunt deze pagina verlaten, we sturen u een e-mail met het transcriptieresultaat.',
  send_your_result: 'We sturen u een e-mail met het transcriptieresultaat.',
  transcribe_audio_thank:
    'Bedankt voor het gebruik van onze transcriptieservice! We werken hard aan het transcriberen van uw audio en waarderen uw geduld.',
  transcribe_audio_no_stay:
    'Het transcriberen kan even duren, maar maak u geen zorgen, u hoeft niet op deze pagina te blijven. We sturen u de resultaten per e-mail zodra ze klaar zijn.',
  date: 'Datum',
  sign_up_to_access_more_functions:
    'Registreer om toegang te krijgen tot meer functies',
  you_can_use_functions:
    'U kunt de volgende functies gebruiken na het inloggen',
  know_more: 'Meer informatie',
  modify_transcription: 'Teksttranscriptie aanpassen',
  play_audio: 'Audio afspelen',
  content_filters: 'Inhoudsfilters',
  export_diff_type: 'Verschillende bestandstypen exporteren',
  screenshots: 'Screenshots',
  free_trial: 'Gratis proefversie',
  pricing: 'Prijzen',
  faq: 'Veelgestelde vragen',
  contact: 'Contact',
  login: 'Inloggen',
  register: 'Registreren',
  please_login_before_purchase: 'Log in voordat u aankoop doet',
  no_records_found: 'Geen gegevens gevonden',
  x_minutes: '{x} Minuten',
  confirm: 'Bevestigen',
  notification_mobile_app: 'Mobiele app-melding',
  notification_mobile_app_des:
    'Ontvang een melding op de mobiele app wanneer het transcriptieresultaat klaar is',
  notification_email: 'E-mailmelding',
  notification_email_des:
    'Ontvang een e-mailmelding wanneer het transcriptieresultaat klaar is',
  video: 'Video',
  or: 'Of',
  sign_in_with_google: 'Aanmelden met Google',
  sign_in_with_apple: 'Aanmelden met Apple',
  login_method: 'Aanmeldmethode',
  delete_account: 'Account verwijderen',
  delete_account_confirm_3_time:
    'Druk 3 keer op de verwijderknop om te bevestigen',
  popup_blocked: 'Pop-up geblokkeerd',
  account_info: 'Accountinformatie',
  redeem: 'Inwisselen',
  redeem_code: 'Code inwisselen',
  summary_des1:
    'De samenvattingsservice biedt een samenvatting van het transcriptieresultaat. Het kan het transcriptieresultaat samenvatten in een paar zinnen.',
  summary_des2:
    'Je oude samenvatting wordt vervangen door de nieuwe samenvatting.',
  consume_transcription_quota: 'Transcriptiequota verbruiken',
  summary: 'Samenvatting',
  query: 'Vraag',
  mins_per_query: '{x} minuten per vraag',
  information_not_found: 'Informatie niet gevonden',
  permission_deny: 'Toestemming geweigerd',
  please_login_to_check_the_result: 'Log in om het resultaat te controleren',
  audio_length: 'Audio lengte',
  delete_permanently: 'Permanent verwijderen',
  are_you_sure_to_delete_permanently:
    'Weet u zeker dat u permanent wilt verwijderen?',
  delete_audios_move_to_bin:
    'De verwijderde records worden naar de prullenbak verplaatst. Alle records in de prullenbak worden na 30 dagen verwijderd.',
  payment_id: 'Betalings-ID',
  current_plan: 'Huidig abonnement',
  plan_name: 'Abonnementsnaam',
  expire_date: 'Vervaldatum',
  subscription_method: 'Abonnementsmethode',
  payment_method: 'Betalingsmethode',
  payment_history: 'Betalingsgeschiedenis',
  amount: 'Bedrag',
  invoice: 'Factuur',
  receipt: 'Ontvangstbewijs',
  link: 'Link',
  edit: 'Bewerken',
  back: 'Terug',
  return: 'Terugkeren',
  cancel_subscription: 'Abonnement opzeggen',
  cancel_subscription_google_play:
    'Op uw Android-apparaat > Ga naar Google Play Store > Account > Betalingen en abonnementen > Abonnementen > SoundType AI > Abonnement opzeggen',
  cancel_subscription_apple_store:
    'Op uw iOS-apparaat > Ga naar Instellingen > [uw naam] > Abonnementen > SoundType AI > Abonnement opzeggen',
  delete_account_des:
    'Account verwijderen zal al uw gegevens permanent verwijderen.',
  delete_account_subscription:
    'Als u een abonnement heeft, zeg dan eerst uw abonnement op.',
  keep_subscription: 'Abonnement behouden',
  cancel_subscription_feature1: 'Opnames',
  cancel_subscription_feature1_des:
    'U kunt alleen de eerste {x} minuten van uw opnames bekijken.',
  cancel_subscription_feature2: 'Transcriptietijd',
  cancel_subscription_feature2_des:
    'U kunt per maand slechts {x} minuten transcriberen.',
  cancel_subscription_count:
    'Druk alstublieft {x} keer op de knop om te bevestigen',
  next_payment_date: 'Volgende betaaldatum',
  basic_plan: 'Basisplan',
  resume_subscription: 'Abonnement hervatten',
  active: 'Actief',
  expired: 'Verlopen',
  auto_renewal_clause: 'Automatische verlengingsclausule',
  auto_renewal_payment_web:
    'Uw abonnement wordt automatisch verlengd, tenzij u het ten minste 24 uur voor het einde van de huidige periode annuleert. Uw account wordt binnen 24 uur voor het einde van de huidige periode belast voor verlenging. U kunt uw abonnementen beheren en annuleren door naar "Instellingen" > "Facturering" te gaan.',
  auto_renewal_terms_and_conditions:
    'Door te abonneren, gaat u akkoord met onze Algemene Voorwaarden en Privacybeleid.',
  payment: 'Betaling',
  monthly: 'Maandelijks',
  annual: 'Jaarlijks',
  minutes_per_month: '{x} minuten/maand',
  buy_now: 'Koop Nu',
  yearly: 'Jaarlijks',
  yearly_save: 'Bespaar tot {x}%',
  pro_plan: 'Pro',
  business_plan: 'Zakelijk',
  enterprise_plan: 'Onderneming',
  everything_in_basic: 'Alles in Basic, plus',
  everything_in_pro: 'Alles in Pro, plus',
  everything_in_business: 'Alles in Zakelijk, plus',
  ai_chat_x: 'AI Chat x {x}',
  ai_summary_x: 'AI Samenvatting x {x}',
  max_x_minutes_per_transcription: 'Max {x} minuten per transcriptie',
  basic_des2: 'Spreker identificatie',
  basic_des3: 'Audio/video bestand transcriptie',
  basic_des4: 'Transcripten bekijken & bewerken',
  pro_des2: 'Prioriteitswachtrij',
  pro_des3: 'Exporteren in SRT formaat',
  pro_des4: 'Onbeperkte AI Samenvatting',
  business_des1: 'Gebruiksanalyse rapport',
  business_des2: 'Samenwerkingsruimte met teamleden',
  business_des3: 'Deel en bewerk transcripten binnen je team.',
  business_des4: 'Deelrechten beheren',
  coming_soon: 'Binnenkort Beschikbaar',
  get_started: 'Beginnen',
  bill_annually_x: 'Jaarlijks gefactureerd {x}',
  no_credit_card_required: 'Geen creditcard vereist',
  save_x: 'Bespaar {x}%',
  price_per_month: ' / maand',
  not_available: 'Niet beschikbaar',
  pricing_title: 'Prijzen',
  upgrade: 'Upgrade',
  ask_question_to_your_audio: 'Stel je vraag aan je audio',
  monthly_minutes: 'Maandelijkse minuten',
  payment_interval: 'Betalingsinterval',
  annually: 'Jaarlijks',
  billing: 'Facturering',
  subscription_plan: 'Abonnement',
  please_cancel_your_current_subscription_first:
    'Annuleer eerst je huidige abonnement',
  please_cancel_your_current_subscription_first_des:
    'Om je abonnementsplan te wijzigen, moet je eerst je huidige abonnement annuleren.',
  cancel_subscription_instruction:
    'Ga naar Instellingen > Facturering > Abonnement Annuleren',
  website_version: 'Websiteversie',
  change_to_annual_plan: 'Wijzig naar Jaarplan',
  update_annual_mobile_platform_reminder: 'Herinnering',
  update_annual_mobile_platform_reminder_des:
    'Let op dat je je huidige abonnement op {x} moet annuleren voordat je overstapt op het jaarplan. Anders word je dubbel belast.',
  i_understand: 'Ik begrijp het',
  no_credit: 'Niet genoeg minuten',
  re_submit: 'Opnieuw indienen',
  loading_audio: 'Audio laden',
  your_password: 'Uw wachtwoord',
  home: 'Home',
  title_a_z: 'Titel A-Z',
  title_z_a: 'Titel Z-A',
  older_first: 'Oudste eerst',
  newest_first: 'Nieuwste eerst',
  table_item_count: '{x} - {y} van {z} items',
  starred: 'Sterren',
  search: 'Zoeken',
  create_folder: 'Map maken',
  folder_name: 'Mapnaam',
  create: 'Maken',
  export_preview: 'Voorbeeld exporteren',
  combine_segments: 'Segmenten combineren',
  export_audio_only:
    'Je kunt alleen audio exporteren wanneer de audio wordt getranscribeerd.',
  transcribe_remaining_x_minutes: 'Transcribeer de resterende {x} minuten',
  x_minutes_remain_be_transcribed: '{x} minuten blijven te transcriberen',
  upgrade_plan_to_transcribe:
    'Upgrade je plan om de volledige audio te transcriberen',
  only_paid_user_can_use:
    'Alleen betalende gebruikers kunnen de resterende minuten transcriberen',
  converting_to_audio: 'Omzetten naar audio',
  move_to_folder: 'Verplaatsen naar map',
  move_audios_to_folder: "Verplaats de geselecteerde audio's naar de map",
  please_select_folder: 'Selecteer een map',
  empty_folder: 'Lege map',
  move: 'Verplaatsen',
  user_not_found:
    'Gebruiker niet gevonden. De opgegeven gebruiker kon niet worden gevonden. Controleer de gegevens en probeer het opnieuw, of neem contact op met de ondersteuning voor verdere hulp.',
  invalid_link: 'Ongeldige link',
  translation: 'Vertaling',
  translate: 'Vertalen',
  translate_des1:
    'De vertaaldienst biedt een AI-vertaling van het transcriptieresultaat. Het kan het transcriptieresultaat naar een andere taal vertalen.',
  translate_des2: 'De vertaalde tekst is op dit moment niet bewerkbaar.',
  translate_des3: 'Uw oude vertaling wordt vervangen door de nieuwe vertaling.',
  translating: 'Vertalen',
  translating_des:
    'Het vertaalproces is aan de gang. Een ogenblik geduld alstublieft. Wij laten het u weten wanneer de vertaling klaar is.',
  please_select_language: 'Selecteer een taal',
  display_language: 'Weergavetaal',
  both: 'Beide',
  original: 'Origineel',
  translated: 'Vertaald',
  default_payment_currency_x_set:
    'Uw betalingsvaluta is ingesteld op {x}. Als u dit wilt wijzigen, neem dan contact met ons op via {email} .',
  invalid_link_des:
    'De YouTube-link is ongeldig. Livevideo’s, afspeellijsten, privé-inhoud, leeftijdsgebonden of regiogeblokkeerde inhoud worden niet ondersteund.',
  free_member_limited_view:
    'Gebruikers van het basisplan hebben alleen toegang tot de eerste {x} minuten van het transcriptieresultaat.',
  free_user_service_once:
    'Gebruikers van het basisplan kunnen deze dienst slechts één keer gebruiken.',
  free_user_summary:
    'Gebruikers van het basisplan kunnen deze dienst slechts één keer gebruiken. Upgrade naar Pro om meer audio’s samen te vatten.',
  free_user_query:
    'Gebruikers van het basisplan kunnen deze dienst slechts één keer gebruiken. Upgrade naar Pro om meer vragen te stellen.',
  cancel_subscription_des:
    'Uw abonnement wordt beëindigd aan het einde van de huidige factureringscyclus ({x}). Na die datum wordt u teruggezet naar het basisplan en heeft u geen toegang meer tot de functies van het Pro-plan:',
  free_member_limited_transcribe_view:
    'Alleen de eerste {x} minuten worden getranscribeerd en zichtbaar voor gebruikers van het basisplan.',
  reset_password_email_sent:
    'Controleer uw e-mail. Als dit account bestaat, wordt een e-mail voor het resetten van het wachtwoord verzonden.',
  m_downloads: '1M+ Downloads',
  provider: 'Provider',
  size: 'Grootte',
  category: 'Categorie',
  productivity: 'Productiviteit',
  languages: 'Talen',
  languages_90: '90+ talen',
  app_des:
    'Transformeer gesproken woorden moeiteloos naar tekst met SoundType AI! Onze geavanceerde app converteert spraakopnames en audiobestanden naar uiterst nauwkeurige teksttranscripties. Of u nu werkt met vergaderingen, interviews, podcasts of videocontent, SoundType AI biedt precisie en efficiëntie.<br><br>Belangrijkste kenmerken:<br>- Geavanceerde sprekeridentificatie: Onderscheid moeiteloos individuele sprekers.<br>- AI-aangedreven samenvattingen: Genereer beknopte en informatieve samenvattingen van uw transcripties.<br>- Wereldwijde taalondersteuning: Transcribeer gesprekken in meer dan 90 talen met gemak.<br>- Geavanceerde audiofuncties: Ontworpen voor professionals, makers en bedrijven.<br><br>Zeg vaarwel tegen tijdrovende handmatige transcripties en kies voor een slimmere, snellere manier om audio om te zetten in tekst met SoundType AI.',
  click_or_drop_file: 'Klik of sleep audio / video',
  remain_x_y_minutes: 'Resterend {x}/{y} minuten',
  my_account: 'Mijn account',
  please_fill_your_email: 'Vul alstublieft uw e-mailadres in',
  please_select_language_first: 'Selecteer eerst een taal',
  please_select_audio_first: 'Selecteer eerst een audio',
  please_fill_youtube_link: 'Vul alstublieft de YouTube-link in',
  speaker_person_x: 'Spreker {x}',
  sign_up_to_unlock_des:
    'Meld u aan om transcripties te bewerken, bestanden te exporteren en toegang te krijgen tot AI-aangedreven samenvattingen, vertalingen en meer. Vereenvoudig vandaag nog uw workflow!',
  your_audio_being_transcribed: 'Uw audio wordt getranscribeerd...',
  sign_up_to_unlock: 'Meld u aan om meer functies te verkennen',
  try_for_free_title: 'Probeer gratis',
  try_for_free_subtitle:
    'Ervaar verbeterde AI-kracht, superieure nauwkeurigheid en geavanceerde functies',
  purchase_success: 'Betaling geslaagd!',
  purchase_success_des:
    'Bedankt voor uw aankoop. Uw transactie is succesvol voltooid.',
  auto_redirect_to_dashboard:
    'U wordt binnenkort doorgestuurd naar uw dashboard. Als de doorgang binnen 5 seconden niet plaatsvindt, klik dan op de volgende link om handmatig verder te gaan:',
  need_assistance: 'Hulp nodig? Neem contact met ons op via {email}',
  invalid_format: 'Ongeldig formaat',
}
