export const id = {
  sign_in: 'Masuk',
  email: 'Email',
  password: 'Kata Sandi',
  i_agree_to_toc: 'Saya setuju dengan syarat dan ketentuan.',
  terms_and_conditions: 'Syarat dan ketentuan',
  privacy_policy: 'Kebijakan privasi',
  already_have_an_account: 'Sudah punya akun?',
  sign_up: 'Daftar',
  dont_have_an_account: 'Belum punya akun?',
  forgot_password: 'Lupa kata sandi?',
  email_already_in_use: 'Email sudah digunakan',
  weak_password: 'Kata sandi lemah',
  too_many_attempts: 'Terlalu banyak percobaan',
  invalid_email_or_password: 'Email atau kata sandi tidak valid',
  user_already_registered: 'Pengguna sudah terdaftar',
  unexpected_error: 'Kesalahan tak terduga',
  audios: 'Audio',
  name: 'Nama',
  auto: 'Otomatis',
  auto_detect: 'Deteksi otomatis',
  speaker_num: 'Jumlah Pembicara',
  speaker_num_reason:
    'Jika Anda memberi tahu kami jumlah pembicara, kami akan memberikan hasil transkripsi yang lebih baik.',
  audio_language: 'Bahasa Audio',
  your_email: 'Email Anda',
  email_result:
    'Kami akan mengirimkan email kepada Anda dengan hasil transkripsi.',
  provide_language:
    'Jika Anda memberi tahu kami bahasa audio, kami akan memberikan hasil transkripsi yang lebih baik.',
  upload_audio: 'Unggah Audio',
  upload_file: 'Unggah File',
  process_audio: 'Memproses File Anda',
  process_audio_des:
    'Harap tunggu sementara audio Anda ditranskripsi. Ini mungkin memakan waktu beberapa menit.',
  uploading: 'Mengunggah',
  pending: 'Tertunda',
  uploaded: 'Diunggah',
  failed: 'Gagal',
  status: 'Status',
  language: 'Bahasa',
  speakers: 'Pembicara',
  duration: 'Durasi',
  done: 'Selesai',
  close: 'Tutup',
  queuing: 'Mengantre',
  created_at: 'Dibuat pada',
  records: 'Rekaman',
  transcribe: 'Transkripsi',
  delete: 'Hapus',
  processing: 'Memproses',
  from_youtube: 'Dari Youtube',
  upload_files: 'Unggah file',
  place_youtube_link_here: 'Tempatkan tautan Youtube di sini',
  submit: 'Kirim',
  email_notification:
    'Waktu penyelesaian bergantung pada panjang dan kompleksitas file audio. Secara umum, Anda dapat mengharapkan menerima transkripsi Anda dalam beberapa jam. Kami akan memberi tahu Anda melalui email segera setelah transkripsi selesai.',
  filters: 'Filter',
  filter: 'Filter',
  replace: 'Ganti',
  share_and_export: 'Bagikan dan Ekspor',
  replace_all: 'Ganti Semua',
  next: 'Berikutnya',
  previous: 'Sebelumnya',
  find: 'Cari',
  replace_with: 'Ganti dengan',
  x_of_x: '{current} dari {total}',
  scroll_lock: 'Kunci Gulir',
  tags: 'Tag',
  bookmark: 'Penanda',
  audio: 'Audio',
  cancel: 'Batal',
  export: 'Ekspor',
  export_format: 'Format ekspor',
  copy_text: 'Salin teks',
  monologue: 'Monolog',
  include_timestamps: 'Sertakan cap waktu',
  include_speaker_names: 'Sertakan nama pembicara',
  combine_same_speaker: 'Gabungkan segmen pembicara yang sama',
  export_mode: 'Mode ekspor',
  no_preview: 'Tidak ada pratinjau',
  copy: 'Salin',
  copied: 'Disalin',
  unlock_pro_feature:
    "Untuk membuka fitur ini, tingkatkan ke paket langganan 'Pro'.",
  pro_features: 'Fitur Pro',
  pro_minutes: '1800 menit / bulan',
  pro_interact_with_audio: 'Berinteraksi dengan audio',
  pro_interact_with_audio_des: 'Ajukan pertanyaan ke audio Anda',
  pro_summary: 'Ringkasan AI',
  pro_summary_des: 'Meringkas audio Anda',
  pro_priority: 'Antrean Prioritas',
  pro_priority_des: 'Memproses audio dengan prioritas',
  pro_multi_speakers: 'Pengakuan Multi-Pembicara',
  pro_multi_speakers_des:
    'Secara akurat mendeteksi dan memberi label pembicara yang berbeda',
  redirect_to_stripe:
    'Anda akan dialihkan ke Stripe untuk menyelesaikan pembayaran.',
  x_per_month: '{x} / Bulan',
  x_per_year: '{x} / Tahun',
  trash: 'Sampah',
  dashboard: 'Dasbor',
  logout: 'Keluar',
  are_you_sure_to_delete:
    'Apakah Anda yakin untuk menghapus rekaman yang dipilih?',
  restore: 'Pulihkan',
  are_you_sure_to_restore:
    'Apakah Anda yakin untuk memulihkan rekaman yang dipilih?',
  deleted_at: 'Dihapus pada',
  hidden_segment: '{x} segmen tersembunyi setelah {y} menit.',
  settings: 'Pengaturan',
  subscription: 'Langganan',
  default_transcription_language: 'Bahasa Transkripsi Default',
  contact_us: 'Hubungi Kami',
  security: 'Keamanan',
  about_us: 'Tentang Kami',
  download_app: 'Unduh Aplikasi',
  download: 'Unduh',
  app_store: 'App Store',
  play_store: 'Play Store',
  upload_voice: 'Unggah suara',
  no_audio_found: 'Tidak ada audio yang ditemukan',
  please_input_valid_email_first:
    'Harap masukkan email yang valid terlebih dahulu',
  handling_audio:
    'Kami sedang mentranskripsi audio Anda. Mohon tunggu sebentar.',
  welcome_to_leave:
    'Anda dipersilakan untuk meninggalkan halaman ini karena kami akan mengirimkan email kepada Anda dengan hasil transkripsi.',
  send_your_result:
    'Kami akan mengirimkan email kepada Anda dengan hasil transkripsi.',
  transcribe_audio_thank:
    'Terima kasih telah menggunakan layanan transkripsi kami! Kami bekerja keras untuk mentranskripsi audio Anda dan menghargai kesabaran Anda.',
  transcribe_audio_no_stay:
    'Transkripsi Anda mungkin memerlukan waktu, tetapi jangan khawatir, Anda tidak perlu tinggal di halaman ini. Kami akan mengirimkan hasilnya melalui email segera setelah selesai.',
  date: 'Tanggal',
  sign_up_to_access_more_functions:
    'Daftar untuk mengakses lebih banyak fungsi',
  you_can_use_functions: 'Anda dapat menggunakan fungsi berikut setelah masuk',
  know_more: 'Pelajari lebih lanjut',
  modify_transcription: 'Modifikasi transkripsi teks',
  play_audio: 'Putar audio',
  content_filters: 'Filter konten',
  export_diff_type: 'Ekspor berbagai jenis file',
  screenshots: 'Tangkapan layar',
  free_trial: 'Uji Coba Gratis',
  pricing: 'Harga',
  faq: 'FAQ',
  contact: 'Kontak',
  login: 'Masuk',
  register: 'Daftar',
  please_login_before_purchase: 'Harap masuk sebelum membeli',
  no_records_found: 'Tidak ada rekaman yang ditemukan',
  x_minutes: '{x} Menit',
  confirm: 'Konfirmasi',
  notification_mobile_app: 'Notifikasi Aplikasi Seluler',
  notification_mobile_app_des:
    'Terima notifikasi aplikasi seluler saat hasil transkripsi siap',
  notification_email: 'Notifikasi Email',
  notification_email_des: 'Terima notifikasi email saat hasil transkripsi siap',
  video: 'Video',
  or: 'Atau',
  sign_in_with_google: 'Masuk dengan Google',
  sign_in_with_apple: 'Masuk dengan Apple',
  login_method: 'Metode Masuk',
  delete_account: 'Hapus Akun',
  delete_account_confirm_3_time:
    'Tekan tombol hapus 3 kali untuk mengonfirmasi',
  popup_blocked: 'Popup diblokir',
  account_info: 'Informasi Akun',
  redeem: 'Tukar',
  redeem_code: 'Tukar Kode',
  summary_des1:
    'Layanan ringkasan menyediakan ringkasan dari hasil transkripsi. Ini dapat meringkas hasil transkripsi menjadi beberapa kalimat.',
  summary_des2: 'Ringkasan lama Anda akan digantikan oleh ringkasan baru.',
  consume_transcription_quota: 'Gunakan kuota transkripsi',
  summary: 'Ringkasan',
  query: 'Kueri',
  mins_per_query: '{x} menit per kueri',
  information_not_found: 'Informasi tidak ditemukan',
  permission_deny: 'Izin ditolak',
  please_login_to_check_the_result: 'Harap masuk untuk memeriksa hasilnya',
  audio_length: 'Panjang Audio',
  delete_permanently: 'Hapus Secara Permanen',
  are_you_sure_to_delete_permanently:
    'Apakah Anda yakin untuk menghapus secara permanen?',
  delete_audios_move_to_bin:
    'Rekaman yang dihapus akan dipindahkan ke tempat sampah. Semua rekaman di tempat sampah akan dihapus setelah 30 hari.',
  payment_id: 'ID Pembayaran',
  current_plan: 'Paket saat ini',
  plan_name: 'Nama paket',
  expire_date: 'Tanggal kedaluwarsa',
  subscription_method: 'Metode langganan',
  payment_method: 'Metode pembayaran',
  payment_history: 'Riwayat pembayaran',
  amount: 'Jumlah',
  invoice: 'Faktur',
  receipt: 'Tanda terima',

  link: 'Tautan',
  edit: 'Sunting',
  back: 'Kembali',
  return: 'Kembali',
  cancel_subscription: 'Batalkan langganan',
  cancel_subscription_google_play:
    'Di perangkat Android Anda > Buka Google Play Store > Akun > Pembayaran dan langganan > Langganan > SoundType AI > Batalkan langganan',
  cancel_subscription_apple_store:
    'Di perangkat iOS Anda > Buka Pengaturan > [nama Anda] > Langganan > SoundType AI > Batalkan langganan',
  delete_account_des:
    'Menghapus akun akan menghapus semua data Anda secara permanen.',
  delete_account_subscription:
    'Jika Anda memiliki langganan, harap batalkan langganan Anda terlebih dahulu.',
  keep_subscription: 'Pertahankan Langganan',
  cancel_subscription_feature1: 'Rekaman',
  cancel_subscription_feature1_des:
    'Anda hanya dapat melihat {x} menit pertama dari rekaman Anda.',
  cancel_subscription_feature2: 'Waktu transkripsi',
  cancel_subscription_feature2_des:
    'Anda hanya dapat mentranskripsi {x} menit per bulan.',
  cancel_subscription_count: 'Harap tekan tombol {x} kali untuk mengonfirmasi',
  next_payment_date: 'Tanggal pembayaran berikutnya',
  basic_plan: 'Paket Dasar',
  resume_subscription: 'Lanjutkan langganan',
  active: 'Aktif',
  expired: 'Kedaluwarsa',
  auto_renewal_clause: 'Klausul pembaruan otomatis',
  auto_renewal_payment_web:
    'Langganan Anda akan diperpanjang secara otomatis kecuali Anda membatalkannya setidaknya 24 jam sebelum akhir periode saat ini. Akun Anda akan dikenakan biaya untuk perpanjangan dalam 24 jam sebelum akhir periode saat ini. Anda dapat mengelola dan membatalkan langganan Anda dengan membuka "Pengaturan" > "Penagihan".',
  auto_renewal_terms_and_conditions:
    'Dengan berlangganan, Anda menyetujui Syarat dan Ketentuan serta Kebijakan Privasi kami.',
  payment: 'Pembayaran',
  monthly: 'Bulanan',
  annual: 'Tahunan',
  minutes_per_month: '{x} menit/bulan',
  buy_now: 'Beli Sekarang',
  yearly: 'Tahunan',
  yearly_save: 'Hemat hingga {x}%',
  pro_plan: 'Pro',
  business_plan: 'Bisnis',
  enterprise_plan: 'Enterprise',
  everything_in_basic: 'Segala sesuatu di Dasar, ditambah',
  everything_in_pro: 'Segala sesuatu di Pro, ditambah',
  everything_in_business: 'Segala sesuatu di Bisnis, ditambah',
  ai_chat_x: 'AI Chat x {x}',
  ai_summary_x: 'AI Ringkasan x {x}',
  max_x_minutes_per_transcription: 'Maks {x} menit per transkripsi',
  basic_des2: 'Identifikasi pembicara',
  basic_des3: 'Transkripsi file audio/video',
  basic_des4: 'Tinjau & edit transkrip',
  pro_des2: 'Antrean Prioritas',
  pro_des3: 'Ekspor dalam format SRT',
  pro_des4: 'Ringkasan AI tanpa batas',
  business_des1: 'Laporan analitik penggunaan',
  business_des2: 'Ruang kerja kolaboratif dengan anggota tim',
  business_des3: 'Bagikan dan edit transkrip dalam tim Anda.',
  business_des4: 'Kontrol izin berbagi',
  coming_soon: 'Segera Hadir',
  get_started: 'Mulai',
  bill_annually_x: 'Ditagih {x} setiap tahun',
  no_credit_card_required: 'Tidak perlu kartu kredit',
  save_x: 'Hemat {x}%',
  price_per_month: ' / bulan',
  not_available: 'Tidak tersedia',
  pricing_title: 'Harga',
  upgrade: 'Tingkatkan',
  ask_question_to_your_audio: 'Ajukan pertanyaan ke audio Anda',
  monthly_minutes: 'Menit bulanan',
  payment_interval: 'Interval pembayaran',
  annually: 'Setiap tahun',
  billing: 'Penagihan',
  subscription_plan: 'Paket',
  please_cancel_your_current_subscription_first:
    'Harap batalkan langganan Anda saat ini terlebih dahulu',
  please_cancel_your_current_subscription_first_des:
    'Untuk mengubah paket langganan Anda, Anda perlu membatalkan langganan Anda saat ini terlebih dahulu.',
  cancel_subscription_instruction:
    'Buka Pengaturan > Penagihan > Batalkan Langganan',
  website_version: 'Versi Situs Web',
  change_to_annual_plan: 'Beralih ke Paket Tahunan',
  update_annual_mobile_platform_reminder: 'Pengingat',
  update_annual_mobile_platform_reminder_des:
    'Harap diberitahukan bahwa Anda perlu membatalkan langganan Anda saat ini pada {x} sebelum beralih ke paket tahunan. Jika tidak, Anda akan dikenakan biaya dua kali.',
  i_understand: 'Saya mengerti',
  no_credit: 'Tidak cukup menit',
  re_submit: 'Kirim ulang',
  loading_audio: 'Memuat audio',
  your_password: 'Kata sandi Anda',
  home: 'Beranda',
  title_a_z: 'Judul A-Z',
  title_z_a: 'Judul Z-A',
  older_first: 'Terlebih dahulu yang lama',
  newest_first: 'Terlebih dahulu yang baru',
  table_item_count: '{x} - {y} dari {z} item',
  starred: 'Berbintang',
  search: 'Cari',
  create_folder: 'Buat folder',
  folder_name: 'Nama folder',
  create: 'Buat',
  export_preview: 'Pratinjau ekspor',
  combine_segments: 'Gabungkan segmen',
  export_audio_only:
    'Anda hanya dapat mengekspor audio saat audio sedang ditranskripsi.',
  transcribe_remaining_x_minutes: 'Transkripsi sisa {x} menit',
  x_minutes_remain_be_transcribed: '{x} menit tersisa untuk ditranskripsi',
  upgrade_plan_to_transcribe:
    'Tingkatkan paket Anda untuk mentranskripsi seluruh audio',
  only_paid_user_can_use:
    'Hanya pengguna berbayar yang dapat mentranskripsi menit yang tersisa',
  converting_to_audio: 'Mengonversi ke audio',
  move_to_folder: 'Pindahkan ke folder',
  move_audios_to_folder: 'Pindahkan audio yang dipilih ke folder',
  please_select_folder: 'Silakan pilih folder',
  empty_folder: 'Folder kosong',
  move: 'Pindahkan',
  user_not_found:
    'Pengguna tidak ditemukan. Pengguna yang ditentukan tidak dapat ditemukan. Silakan periksa detailnya dan coba lagi, atau hubungi dukungan jika memerlukan bantuan lebih lanjut.',
  invalid_link: 'Tautan tidak valid',
  translation: 'Terjemahan',
  translate: 'Terjemahkan',
  translate_des1:
    'Layanan terjemahan menyediakan terjemahan AI dari hasil transkripsi. Ini dapat menerjemahkan hasil transkripsi ke dalam bahasa lain.',
  translate_des2: 'Teks terjemahan tidak dapat diedit saat ini.',
  translate_des3: 'Terjemahan lama Anda akan digantikan oleh terjemahan baru.',
  translating: 'Menerjemahkan',
  translating_des:
    'Proses terjemahan sedang berlangsung. Mohon tunggu sebentar. Kami akan memberi tahu Anda saat terjemahan sudah siap.',
  please_select_language: 'Silakan pilih bahasa',
  display_language: 'Bahasa tampilan',
  both: 'Keduanya',
  original: 'Asli',
  translated: 'Diterjemahkan',
  default_payment_currency_x_set:
    'Mata uang pembayaran Anda diatur ke {x}. Jika Anda ingin mengubahnya, silakan hubungi kami di {email} .',
  invalid_link_des:
    'Tautan YouTube tidak valid. Video langsung, daftar putar, konten pribadi, dengan batasan usia, atau terkunci wilayah tidak didukung.',
  free_member_limited_view:
    'Pengguna paket dasar hanya memiliki akses ke {x} menit pertama dari hasil transkripsi.',
  free_user_service_once:
    'Pengguna paket dasar hanya dapat menggunakan layanan ini sekali.',
  free_user_summary:
    'Pengguna paket dasar hanya dapat menggunakan layanan ini sekali. Harap tingkatkan ke paket Pro untuk meringkas lebih banyak audio.',
  free_user_query:
    'Pengguna paket dasar hanya dapat menggunakan layanan ini sekali. Harap tingkatkan ke paket Pro untuk mengajukan lebih banyak pertanyaan.',
  cancel_subscription_des:
    'Langganan Anda akan dibatalkan pada akhir siklus penagihan saat ini ({x}). Setelah tanggal tersebut, Anda akan diturunkan ke paket dasar dan tidak lagi dapat mengakses fitur paket Pro:',
  free_member_limited_transcribe_view:
    'Hanya {x} menit pertama yang akan ditranskripsi dan dapat dilihat oleh pengguna paket dasar.',
  reset_password_email_sent:
    'Silakan periksa email Anda. Jika akun ini ada, email pengaturan ulang kata sandi akan dikirimkan.',
  m_downloads: '1M+ Unduhan',
  provider: 'Penyedia',
  size: 'Ukuran',
  category: 'Kategori',
  productivity: 'Produktivitas',
  languages: 'Bahasa',
  languages_90: '90+ bahasa',
  app_des:
    'Ubah kata yang diucapkan menjadi teks dengan mudah menggunakan SoundType AI! Aplikasi canggih kami mengubah rekaman suara dan file audio menjadi transkripsi berbasis teks yang sangat akurat. Baik Anda bekerja dengan rapat, wawancara, podcast, atau konten video, SoundType AI memberikan presisi dan efisiensi.<br><br>Fitur utama meliputi:<br>- Identifikasi Pembicara Lanjutan: Bedakan pembicara individu dengan mudah.<br>- Ringkasan Didukung AI: Buat ringkasan transkripsi yang ringkas dan informatif.<br>- Dukungan Bahasa Global: Transkripsikan percakapan dalam lebih dari 90 bahasa dengan mudah.<br>- Fitur Audio Canggih: Dirancang untuk profesional, pembuat konten, dan bisnis.<br><br>Katakan selamat tinggal pada transkripsi manual yang melelahkan dan sambut cara yang lebih cerdas dan cepat untuk mengubah audio menjadi teks dengan SoundType AI.',
  click_or_drop_file: 'Klik atau jatuhkan audio / video',
  remain_x_y_minutes: 'Sisa {x}/{y} menit',
  my_account: 'Akun saya',
  please_fill_your_email: 'Silakan isi email Anda',
  please_select_language_first: 'Silakan pilih bahasa terlebih dahulu',
  please_select_audio_first: 'Silakan pilih audio terlebih dahulu',
  please_fill_youtube_link: 'Silakan isi tautan Youtube',
  speaker_person_x: 'Pembicara {x}',
  sign_up_to_unlock_des:
    'Daftar untuk mengedit transkripsi, mengekspor file, dan mengakses ringkasan, terjemahan, dan lainnya yang didukung AI. Sederhanakan alur kerja Anda hari ini!',
  your_audio_being_transcribed: 'Audio Anda sedang ditranskripsi...',
  sign_up_to_unlock: 'Daftar untuk menjelajahi lebih banyak fitur',
  try_for_free_title: 'Coba Gratis',
  try_for_free_subtitle:
    'Rasakan Kekuatan AI yang Ditingkatkan, Akurasi yang Lebih Baik, dan Fitur Lanjutan',
  purchase_success: 'Pembayaran Berhasil!',
  purchase_success_des:
    'Terima kasih atas pembelian Anda. Transaksi Anda berhasil diselesaikan.',
  auto_redirect_to_dashboard:
    'Anda akan segera diarahkan ke dasbor Anda. Jika pengalihan tidak terjadi dalam 5 detik, klik tautan berikut untuk melanjutkan secara manual:',
  need_assistance: 'Butuh bantuan? Hubungi kami di {email}',
  invalid_format: 'Format tidak valid',
}
