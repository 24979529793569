import { ApiContext } from '@/api_client/types'
import Client from '@/api_client/Client'
import { AuthHelper, makeClient } from '@/api_client/index'
import logIn from '@/api_client/api/logIn'

import getUser from '@/api_client/api/getUser'
import register from './api/register'
import getAudios from './api/getAudios'
import getAudio from './api/getAudio'
import addAudio from './api/addAudio'
import exportFile from './api/exportFile'
import getAudioUUID from '@/api_client/api/getAudioUUID'
import updateSegment from './api/updateSegment'
import updateAudio from './api/updateAudio'
import getProducts from '@/api_client/api/getProducts'
import stripeCheckout from './api/stripeCheckout'
import removeAudios from './api/removeAudios'
import restoreAudios from '@/api_client/api/restoreAudios'
import updateUser from '@/api_client/api/updateUser'
import updateLinkProvider from '@/api_client/api/updateLinkProvider'
import deleteUser from './api/deleteUser'
import redeemCode from '@/api_client/api/redeemCode'
import getAiServerFee from '@/api_client/api/getAiServerFee'
import getMessages from '@/api_client/api/getMessages'
import sendMessage from '@/api_client/api/sendMessage'
import getMessageResponse from '@/api_client/api/getMessageResponse'
import summarizeAudio from '@/api_client/api/summarizeAudio'
import getInvoices from '@/api_client/api/getInvoices'
import getPurchases from './api/getPurchases'
import cancelPurchaseStripe from './api/cancelPurchaseStripe'
import resumePurchaseStripe from './api/resumePurchaseStripe'
import reSubmitAudio from './api/reSubmitAudio'
import getFolders from '@/api_client/api/getFolders'
import createFolder from '@/api_client/api/createFolder'
import updateFolder from '@/api_client/api/updateFolder'
import addTagToFolders from '@/api_client/api/addTagToFolders'
import removeTagToFolders from '@/api_client/api/removeTagToFolders'
import addTagToAudios from '@/api_client/api/addTagToAudios'
import removeTagToAudios from '@/api_client/api/removeTagToAudios'
import transcribeRemaining from '@/api_client/api/transcribeRemaining'
import removeFolders from './api/removeFolders'
import restoreFolders from './api/restoreFolders'
import addAudiosFolder from '@/api_client/api/addAudiosFolder'
import removeAudiosFolder from '@/api_client/api/removeAudiosFolder'
import translateAudio from '@/api_client/api/translateAudio'
import getStripeDefaultCurrency from '@/api_client/api/getStripeDefaultCurrency'
import getPurchaseBySessionId from '@/api_client/api/getPurchaseBySessionId'

class ScClient {
  // local test account: abc1@abc.com, 123456
  // server test account: abc2@abc.com, 123456
  // public backendPath =
  //   process.env.NODE_ENV === 'production'
  //     ? 'https://api.soundtype.ai/'
  //     : 'http://localhost:8000/'

  public backendPath = 'https://api.soundtype.ai/'
  public client: Client
  public config: any
  private apiContext: ApiContext
  // private allowedUserIds = ['1', '51', '166', '9090']
  public isAllowedIPAccess = true

  constructor(isHttps = true) {
    const theBackendPath = isHttps
      ? 'https://api.soundtype.ai/'
      : this.backendPath
    this.client = makeClient({
      host: theBackendPath,
    })
    this.config = { auth: new AuthHelper(), backendUrl: theBackendPath }
    this.apiContext = { client: this.client, config: this.config }
  }

  protected getUser() {
    return getUser(this.apiContext)
  }

  protected deleteUser() {
    return deleteUser(this.apiContext)
  }

  protected logIn(firebaseUid, firebaseToken) {
    return logIn(this.apiContext, firebaseUid, firebaseToken)
  }

  protected reSubmitAudio(audioId) {
    return reSubmitAudio(this.apiContext, audioId)
  }

  protected register(data) {
    return register(this.apiContext, data)
  }

  protected getAudios(query = {}) {
    return getAudios(this.apiContext, query)
  }

  protected addAudio(
    language,
    speaker_num,
    email,
    file = null,
    youtube_url = '',
    anonymous_token = false,
    fileName = null,
    length_seconds = null,
    length_milliseconds = null,
    folder_id = null,
    onProgress = null,
  ) {
    return addAudio(
      this.apiContext,
      language,
      speaker_num,
      email,
      file,
      youtube_url,
      anonymous_token,
      fileName,
      length_seconds,
      length_milliseconds,
      folder_id,
      onProgress,
    )
  }

  protected getAudio(id, query = {}) {
    return getAudio(this.apiContext, id, query)
  }

  protected exportFile(id, query = {}) {
    return exportFile(this.apiContext, id, query)
  }

  protected updateSegment(id, data = {}) {
    return updateSegment(this.apiContext, id, data)
  }

  protected getAudioUUID(uuid) {
    return getAudioUUID(this.apiContext, uuid)
  }

  protected getProducts(lang = 'en', default_currency = '') {
    return getProducts(this.apiContext, lang, default_currency)
  }

  protected getHardCodeProducts(lang = 'en', default_currency = '') {
    return getProducts(this.apiContext, lang, default_currency)
  }

  protected redeemCode(code) {
    return redeemCode(this.apiContext, code)
  }

  protected getInvoices() {
    return getInvoices(this.apiContext)
  }

  protected getPurchases(params = {}) {
    return getPurchases(this.apiContext, params)
  }

  protected cancelPurchaseStripe(id) {
    return cancelPurchaseStripe(this.apiContext, id)
  }

  protected resumePurchaseStripe(id) {
    return resumePurchaseStripe(this.apiContext, id)
  }

  protected getAiServerFee(audioId, type = '') {
    return getAiServerFee(this.apiContext, audioId, type)
  }

  protected getMessages(audioId) {
    return getMessages(this.apiContext, audioId)
  }

  protected sendMessage(audioId, question) {
    return sendMessage(this.apiContext, audioId, question)
  }

  protected summarizeAudio(audioId, summarize_purpose, language) {
    return summarizeAudio(this.apiContext, audioId, summarize_purpose, language)
  }

  protected translateAudio(audioId, language) {
    return translateAudio(this.apiContext, audioId, language)
  }

  protected transcribeRemaining(audioId) {
    return transcribeRemaining(this.apiContext, audioId)
  }

  protected getMessageResponse(messageId) {
    return getMessageResponse(this.apiContext, messageId)
  }

  protected removeAudios(ids, isPermanent = false) {
    return removeAudios(this.apiContext, ids, isPermanent)
  }

  protected updateUser(data) {
    return updateUser(this.apiContext, data)
  }

  protected restoreAudios(ids) {
    return restoreAudios(this.apiContext, ids)
  }

  protected createFolder(name) {
    return createFolder(this.apiContext, name)
  }

  protected getFolders(tag = '', ordering = '', onlyDeleted = false) {
    return getFolders(this.apiContext, {
      tag: tag,
      ordering: ordering,
      only_deleted: onlyDeleted ? '1' : '0',
    })
  }

  protected updateFolder(audioId, data) {
    return updateFolder(this.apiContext, audioId, data)
  }

  protected removeFolders(ids, isPermanent = false) {
    return removeFolders(this.apiContext, ids, isPermanent)
  }

  protected restoreFolders(ids) {
    return restoreFolders(this.apiContext, ids)
  }

  protected addAudiosFolder(folderId, audioIds) {
    return addAudiosFolder(this.apiContext, folderId, audioIds)
  }

  protected removeAudiosFolder(audioIds) {
    return removeAudiosFolder(this.apiContext, audioIds)
  }

  /**
   * Start tags
   */
  protected addTagToAudios(audio_ids: number[], tag: string) {
    return addTagToAudios(this.apiContext, audio_ids, tag)
  }

  protected removeTagToAudios(audio_ids: number[], tag: string) {
    return removeTagToAudios(this.apiContext, audio_ids, tag)
  }

  protected addTagToFolders(folder_ids: number[], tag: string) {
    return addTagToFolders(this.apiContext, folder_ids, tag)
  }

  protected removeTagToFolders(folder_ids: number[], tag: string) {
    return removeTagToFolders(this.apiContext, folder_ids, tag)
  }

  /**
   * End tags
   */
  protected stripeCheckout(
    price_id: string,
    uid: number,
    success_url: string,
    cancel_url: string,
    locate = 'en',
    currency = null,
  ) {
    return stripeCheckout(
      this.apiContext,
      price_id,
      uid,
      success_url,
      cancel_url,
      locate,
      currency,
    )
  }

  protected getPurchaseBySessionId(session_id: string) {
    return getPurchaseBySessionId(this.apiContext, session_id)
  }

  protected getStripeDefaultCurrency() {
    return getStripeDefaultCurrency(this.apiContext)
  }

  protected updateAudio(audioId, data) {
    return updateAudio(this.apiContext, audioId, data)
  }

  protected updateLinkProvider(userInfo) {
    return updateLinkProvider(this.apiContext, userInfo)
  }
}

export default ScClient
